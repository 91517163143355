import * as Sentry from '@sentry/vue'
import { App } from 'vue'

const useSentry = (app: App) => {
  const h = window.location.host
  if (h.startsWith('localhost') || h.startsWith('127.0.0.1')) return

  Sentry.init({
    app,
    dsn: 'https://8ca9fa8de3d24f869afbe40c05bdeb91@o147846.ingest.sentry.io/4504603679457280',
    environment: process.env.NODE_ENV,
    tracesSampleRate: 0.1,
    ignoreErrors: [
      'Network Error',
      'Non-Error promise rejection captured',
      'Non-Error exception captured',
      'Request aborted',
    ],
  })
}

export default {
  Sentry,
  useSentry,
}
