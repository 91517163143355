import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import ViewNotFound from '@/components/views/ViewNotFound.vue'
import routesAuth from '@/router/routes-auth'
import routesNoAuth from '@/router/routes-no-auth'

const routes: Array<RouteRecordRaw> = [
  ...routesAuth,
  ...routesNoAuth,
  {
    path: '/:pathMatch(.*)*',
    component: ViewNotFound,
  },
] as Array<RouteRecordRaw>

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior: (to, from) => {
    if (to.path === from.path) {
      return
    }

    return { top: 0 }
  },
  routes,
})

const onFail = () => {
  const body = document.querySelector('body')
  body.style.visibility = 'visible'
  body.innerHTML = 'Site has changed. Reload after 5 seconds...'
  setTimeout(() => window.location.reload(), 5000)
}

router.onError((error) => {
  console.log(error)

  if (typeof document === 'undefined') return

  onFail()
})

export default router
