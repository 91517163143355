import client from '@/services'
import { populateRemarkIndex } from '@/store/settlement'
import {
  Settlement,
  SettlementItemListResponse,
  SettlementListResponse,
  VatReceipt,
  VatReceiptListResponse,
  VatReceiptNeededResponse,
} from '@/types/services/settlement'

const settlementService = {
  getSettlementItems: async (params?: {
    purchaseCode?: string | string[]
    qualityName?: string | string[]
    settledAtGteq?: string | string[]
    settledAtLteq?: string | string[]
    status?: string | string[]
    page?: number
  }): Promise<SettlementItemListResponse> => {
    try {
      const data = (await client.get('settlement_items', { params })) as SettlementItemListResponse
      populateRemarkIndex(data.items)
      return data
    } catch (e) {
      return Promise.reject(e)
    }
  },
  getSettlements: (params?: {
    id?: string | string[]
    completedAtGteq?: string | string[]
    completedAtLteq?: string | string[]
    page?: number
  }): Promise<SettlementListResponse> => client.get('settlements', { params }),
  getSettlement: (id: number): Promise<Settlement> => client.get(`settlements/${id}`),
  getVatReceipts: (params?: {
    id?: string | string[]
    createdAtGteq?: string | string[]
    createdAtLteq?: string | string[]
    page?: number
  }): Promise<VatReceiptListResponse> => client.get('vat_receipts', { params }),
  getVatReceipt: (id: number): Promise<VatReceipt> => client.get(`vat_receipts/${id}`),
  getVatReceiptNeeded: (): Promise<VatReceiptNeededResponse> => client.get('settlements/vat_receipt_needed'),
}

export default settlementService
