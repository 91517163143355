import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

import useUserStore from '@/store/user'
import { MeResponse } from '@/types/services/user'

const businessInfoCheck = (to: RouteLocationNormalized, next: NavigationGuardNext, me: MeResponse) => {
  if (to.path === '/set-partner-info') {
    next()
    return
  }

  if (me && !me.businessInfoExists) {
    next({ path: '/set-partner-info' })
  }
}

const authCheck = async (to: RouteLocationNormalized, __: RouteLocationNormalized, next: NavigationGuardNext) => {
  const userStore = useUserStore()

  if (userStore.me) {
    businessInfoCheck(to, next, userStore.me)
    next()
    return
  }

  try {
    await userStore.loadAuthToken()
    next()
  } catch (e) {
    next({ path: '/login', query: { redirect: to.fullPath } })
  }
}

export default authCheck
