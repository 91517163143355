import client from '@/services'
import {
  PriceTag,
  ProductOfQuality,
  Quality,
  QualityBasicRequest,
  QualityDetailRequest,
  QualityListResponse,
  QualityPriceTagRequest,
  QualityProductsRequest,
  QualityPropertyListResponse,
} from '@/types/services/quality'

// bulk 가격 구간들의 경우 quantityLteq를 null로 만든다.(어차피 서버에서 연결해줌)
const setBulkLteqAsNull = (priceTags: PriceTag[]) => {
  if ((priceTags || []).length === 0) return

  const bulkTags = priceTags.filter((tag) => tag.range === 'bulk')
  bulkTags.forEach((_, i) => (bulkTags[i].quantityLteq = null))
}

const qualityService = {
  getQuality: (id: number): Promise<Quality> => client.get(`qualities/${id}`),

  getQualities: (params?: {
    page?: number
    name?: string | string[]
    code?: string | string[]
    status?: string | string[]
  }): Promise<QualityListResponse> => client.get('qualities', { params }),

  getQualityProperties: (): Promise<QualityPropertyListResponse> => client.get('qualities/properties'),

  getProducts: (id: number): Promise<{ products: ProductOfQuality[] }> => client.get(`qualities/${id}/products`),

  getPrintingMethods: (): Promise<{ key: string; value: string }> => client.get('qualities/printing_methods'),

  postQualityBasicInfo: (payload: QualityBasicRequest): Promise<Quality> =>
    client.post('qualities/basic_info', payload),

  putQualityBasicInfo: (payload: QualityBasicRequest, id: number): Promise<Quality> =>
    client.put(`qualities/${id}/basic_info`, payload),

  putQualityDetailInfo: (payload: QualityDetailRequest, id: number): Promise<Quality> =>
    client.put(`qualities/${id}/detail_info`, payload),

  putQualityPriceTags: (payload: QualityPriceTagRequest, id: number): Promise<Quality> => {
    if (!['standard_order', 'custom_dye', 'print', 'retail'].includes(payload.$$salesType))
      return Promise.reject(new Error('missing $$salesType'))

    setBulkLteqAsNull(payload.standardOrderPriceTagGroup?.qualityPriceTags)
    setBulkLteqAsNull(payload.printPriceTagGroup?.qualityPriceTags)
    setBulkLteqAsNull(payload.customDyePriceTagGroup?.qualityPriceTags)
    setBulkLteqAsNull(payload.retailPriceTagGroup?.qualityPriceTags)
    return client.put(`qualities/${id}/${payload.$$salesType}_price_tags`, payload)
  },

  putQualityProducts: (payload: QualityProductsRequest, id: number): Promise<Quality> =>
    client.put(`qualities/${id}/products`, payload),

  putQualityMarketingMedias: (payload: { imageIds: number[]; videoIds: number[] }, id: number): Promise<Quality> =>
    client.put(`qualities/${id}/marketing_medias`, payload),

  putQualityPurchaseInfo: (
    payload: { productionMoq: number; productionLeadTime: number },
    id: number,
  ): Promise<Quality> => client.put(`qualities/${id}/production_infos`, payload),
}
export default qualityService
