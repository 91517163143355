const modals = {
  MODAL_PRICE_POLICY_CHANGE: {
    ko: '가격정보 입력기준 변경',
    en: 'Price policy has changed',
  },
  MODAL_PRICE_POLICY_CHANGE_BODY: {
    ko: '상품 가격정보 입력기준이 변경되어 기존에 입력했던 가격정보 업데이트가 필요합니다.\n4개의 구간에 해당하는 가격과 납기일을 작성해주세요.',
    en: 'Price policy has changed. Please update the price and delivery date for the 4 ranges.',
  },
  MODAL_ABORT_EDITING: {
    ko: '페이지를 나가시겠습니까?\n현재까지 입력한 정보는 저장되지 않습니다.',
    en: 'Are you sure you want to leave?\nAny unsaved change will be lost.',
  },
  MODAL_STOCK_CHECK_INTRO: {
    ko: '입력하신 재고 입력 내역을 확인해주세요.',
    en: 'Please check the stock input information you entered.',
  },
  MODAL_STOCK_CHECK_CONFIRM_DESC: {
    ko: '위 내용으로 재고 입력을 확정하시겠습니까? 재고 확정 이후 수정은 불가합니다.\n** 해당 건은 <span class="c-danger">주문확정이 아니므로</span> 고객 필요 수량의 원단을 준비하지 마세요. **',
    en: 'Are you sure with the above information? You cannot change it after confirmation.\n** This is <span class="c-danger">not an order confirmation</span>, so do not prepare the fabric for the customer\'s required quantity. **',
  },
  MODAL_STOCK_CHECK_CONFIRM_TITLE_ONLY_THIS_COLOR: {
    ko: '해당 컬러만 생산 불가능인가요?',
    en: 'Is it impossible to produce only that color?',
  },
  MODAL_STOCK_CHECK_CONFIRM_BODY_ONLY_THIS_COLOR_01: {
    ko: '해당 컬러 상품은',
    en: 'The color product',
  },
  MODAL_STOCK_CHECK_CONFIRM_TITLE_ALL_COLORS: {
    ko: '모든 컬러가 생산 불가능인가요?',
    en: 'Are all colors impossible to produce?',
  },
  MODAL_STOCK_CHECK_CONFIRM_BODY_ALL_COLORS_01: {
    ko: '해당 상품의 모든 컬러는',
    en: 'All colors of the product',
  },
  MODAL_STOCK_CHECK_CONFIRM_BODY_02: {
    ko: '즉시 스와치온 사이트에서 판매 중단',
    en: 'will be discontinued immediately on the SwatchOn site.',
  },
  MODAL_STOCK_CHECK_CONFIRM_BODY_03: {
    ko: '됩니다.',
    en: ' ',
  },
  MODAL_STOCK_CHECK_CONFIRM_BODY_04: {
    ko: '한번 판매 중단 처리 된 상품은',
    en: 'Products that have been discontinued',
  },
  MODAL_STOCK_CHECK_CONFIRM_BODY_05: {
    ko: '재판매가 불가',
    en: 'cannot be resold.',
  },
  MODAL_STOCK_CHECK_CONFIRM_BODY_06: {
    ko: '합니다.',
    en: ' ',
  },
  MODAL_BUSINESS_INFO_IS_MANDATORY: {
    ko: '로그아웃 하시겠습니까?\n사업자 정보설정을 완료해야만 서비스를 이용하실 수 있습니다.',
    en: 'Are you sure you want to log out?\nYou must complete the business information setting to use the service.',
  },
  MODAL_ADD_QUALITY_TITLE: {
    ko: '상품 %s 등록을 완료하시겠습니까?',
    en: 'Are you sure you want to complete item registration for %s?',
  },
  MODAL_ADD_QUALITY_BODY: {
    ko: '입력된 정보는 상품 상세 페이지에서 언제든 확인 및 수정 가능합니다.',
    en: 'Item information can be confirmed and edited on the item page any time later.',
  },
  MODAL_SUSPENDED_SELLER: {
    ko: '거래중단 판매자',
    en: 'Suspended Seller',
  },
  MODAL_SUSPENDED_SELLER_BODY: {
    ko: '스와치온 거래중단 상태의 ID입니다. 추가 문의는 스와치온 MD팀(02-462-1015)으로 연락해 주세요.',
    en: 'This is an ID in the suspended state of Swatchon. For further inquiries, please contact Swatchon MD Team (+82-2-462-1015).',
  },
  MODAL_EDIT_PURCHASE_DATE_TITLE: {
    ko: '출고배송계획 수정',
    en: 'Edit shipping date',
  },
  MODAL_EDIT_PURCHASE_DATE_TITLE_LDIP: {
    ko: 'L/DIP 발송예상일자 변경',
    en: 'Edit L/DIP shipping date',
  },
  MODAL_EDIT_PURCHASE_DATE_COULD_BE_LATE: {
    ko: '선택한 날짜는 납기지연에 해당합니다. 바이어의 컴플레인이 발생할 수 있습니다.',
    en: 'The selected date is a delayed delivery. Buyer complaints may occur.',
  },
  MODAL_EDIT_PURCHASE_DATE_DDM_PICKUP_ENDED: {
    ko: '금일 동대문 종합시장 픽업 마감',
    en: 'Dongdaemun pickup ended today',
  },
  MODAL_EDIT_PURCHASE_DATE_DDM_PICKUP_ENDED_TODO: {
    ko: '금일 동대문 종합시장 픽업은 마감되었습니다. (당일 오후 3시 마감)<br />출고배송일자를 변경해주세요.',
    en: 'Dongdaemun pickup ended today. (Closed at 3pm on the day)<br />Please change the shipping date.',
  },
  MODAL_CONFIRM_EDIT_PURCHASE_DATE: {
    ko: '총 <span class="c-primary">%s</span> 건의 주문상품에 대하여 출고배송계획을 위와 같이 수정하시겠습니까?',
    en: 'Are you sure you want to change the shipping date for a total of <span class="c-primary">%s</span> items?',
  },
  MODAL_CONFIRM_EDIT_PURCHASE_DATE_LDIP: {
    ko: '총 <span class="c-primary">%s</span> 건의 주문상품에 대하여 L/DIP 발송예상일자를 위와 같이 수정하시겠습니까?',
    en: 'Are you sure you want to change the L/DIP shipping date for a total of <span class="c-primary">%s</span> items?',
  },
  MODAL_PRINT_IMAGE: {
    ko: '프린트 이미지 미리보기',
    en: 'Preview Print Image',
  },
  PRINT_IMAGE_SIZE: {
    ko: '프린트 이미지 사이즈',
    en: 'Print Image Size',
  },
  PRINT_IMAGE_SIZE_WARNING: {
    ko: '화면에 보이는 이미지는 실제 인쇄될 색상과 다르게 보일 수 있습니다.',
    en: 'The image on the screen may look different from the actual printed color.',
  },
  PRINT_QUALITY_WIDTH: {
    ko: '원단 폭',
    en: 'Fabric Width',
  },
  PRINTABLE_WIDTH: {
    ko: '프린트 가용폭',
    en: 'Printable Width',
  },
  PREVIEW: {
    ko: '미리보기',
    en: 'Preview',
  },
  DOWNLOAD: {
    ko: '다운로드',
    en: 'Download',
  },
  DOWNLOAD_IMAGE_FILE: {
    ko: '이미지 파일 다운로드',
    en: 'Download the image file',
  },
  IMAGE_BIGGER_THAN_FABRIC: {
    ko: '이미지 너비가 원단 폭보다 <b>클</b> 경우, <b>폭에 맞게 축소</b>',
    en: 'If the image width is <b>bigger</b> than the fabric width, <b>it will be shrunk to fit</b>',
  },
  IMAGE_SMALLER_THAN_FABRIC: {
    ko: '이미지 너비가 원단 폭보다 <b>작을</b> 경우, <b>바둑판 반복</b>',
    en: 'If the image width is <b>smaller</b> than the fabric width, <b>it will be repeated</b>',
  },
  MODAL_BUTTON_MISTAKE: {
    ko: '앗, 실수',
    en: 'Oh, a mistake',
  },
  MODAL_BUTTON_CONFIRM: {
    ko: '네, 확인 했습니다.',
    en: "Yes, I've checked it.",
  },
}

const errors = {
  ERROR_FILE_SIZE: {
    ko: '파일 사이즈는 %sMB 이하여야 합니다.',
    en: 'File size must be less than %sMB.',
  },
  ERROR_HAS_DUPLICATED_VALUE: {
    ko: '중복되는 값이 있습니다.',
    en: 'There is a duplicated value.',
  },
  ERROR_INVALID_FORMAT: {
    ko: '양식에 맞지않습니다.',
    en: 'Invalid format.',
  },
  ERROR_FAILED_TO_UPLOAD_FILE: {
    ko: '파일 업로드에 실패했습니다.',
    en: 'Failed to upload file.',
  },
  ERROR_PHONES_NEEDED: {
    ko: '최소 1개 이상의 모바일 연락처가 필요합니다.',
    en: 'At least one mobile contact is required.',
  },
  ERROR_NO_PRODUCT: {
    ko: '최소 1개 이상의 컬러를 등록해주세요.',
    en: 'Please register at least one color.',
  },
  ERROR_CONFIRM_ALL_PURCHASES: {
    ko: '모든 상품에 대해 수락/취소 처리를 완료해주세요.',
    en: 'Please complete the acceptance/cancellation process for all products.',
  },
  ERROR_ID: {
    ko: '아이디를 다시 확인해주세요.',
    en: 'Please check your ID again.',
  },
  ERROR_PASSWORD: {
    ko: '비밀번호를 다시 확인해주세요.',
    en: 'Please check your password again.',
  },
  ERROR_FAILED_TO_LOGIN: {
    ko: '로그인에 실패했습니다.',
    en: 'Failed to login.',
  },
  ERROR_UPLOAD_VAT_RECEIPT: {
    ko: '세금계산서 이미지 업로드 혹은 승인번호를 입력해주세요.',
    en: 'Please upload the invoice image or enter the approval number.',
  },
  ERROR_REQUIRED: {
    ko: '필수입력항목입니다.',
    en: 'Required field.',
  },
  ERROR_INVALID_EMAIL: {
    ko: '이메일 형식으로 입력해주세요.',
    en: 'Please enter your email address in the format',
  },
  ERROR_DUPLICATED_QUALITY_NAME: {
    ko: '중복된 상품명이 존재합니다.',
    en: 'There is a duplicated product name.',
  },
  ERROR_DUPLICATED_COLOR_NUMBER: {
    ko: '중복된 컬러넘버(%s)는 할당 불가능합니다.',
    en: 'Duplicated color number(%s) is not allowed.',
  },
  ERROR_UPDATING_REGISTER_CANCELLED_QUALITY: {
    ko: '등록취소 상품 정보는 업데이트 할 수 없습니다. 판매를 원하실 경우 상품 관리 담당자에게 연락해주세요.',
    en: 'You cannot update the information of the product that has been cancelled. Please contact the product manager if you want to sell it.',
  },
  ERROR_UPDATING_RETAIL_UNAVAILABLE_CATEGORY: {
    ko: '소매판매가 불가능한 조직(카테고리)입니다.',
    en: 'This category is not available for retail sales.',
  },
  ERROR_API: {
    ko: '오류가 발생했습니다. 다시 시도해주세요.',
    en: 'An error occurred. Please try again.',
  },
  ERROR_NEGATIVE_SURPLUS: {
    ko: '퀄리티 추가단가는 음수가 될 수 없습니다.',
    en: 'The surcharge per yard cannot be negative.',
  },
  ERROR_LOAD_QUALITY: {
    ko: '상품 정보를 불러오는데 실패했습니다. 다시 시도해주세요.',
    en: 'Failed to load product information. Please try again.',
  },
  ERROR_OVER_ZERO: {
    ko: '1 이상의 숫자를 입력해주세요.',
    en: 'Please enter a number greater than 1.',
  },
  ERROR_EQUAL_OR_OVER_ZERO: {
    ko: '0 이상의 숫자를 입력해주세요.',
    en: 'Please enter a number greater than or equal to 0.',
  },
  ERROR_INVALID_RANGE: {
    ko: '구간의 최대수량이 최소수량보다 크거나 같아야합니다.',
    en: 'The maximum quantity of the section must be greater than or equal to the minimum quantity.',
  },
  ERROR_SAMPLE_MUST_BE_LESS_THAN_20: {
    ko: '20 이하의 숫자를 입력해주세요.',
    en: 'Please enter a number less than 20.',
  },
  ERROR_BULK_GTEQ_MUST_BE_LESS_THAN_LTEQ: {
    ko: '%s 미만의 숫자를 입력해주세요.',
    en: 'Please enter a number less than %s.',
  },
  ERROR_PRODUCTION_MOQ_TOO_BIG: {
    ko: '생산최소수량은 %s yds 이하여야 합니다.',
    en: 'Production MOQ must be less than %s yds.',
  },
  ERROR_PRODUCTION_LEAD_TIME_TOO_BIG: {
    ko: '출고예정일은 %s일 이내여야 합니다.',
    en: 'Production lead time must be within %s days.',
  },
  ERROR_CONTENT_RATIO_SUM_EXCEED: {
    ko: '혼용률 합이 100% 이어야 합니다.',
    en: 'The sum of the content ratio must be 100%.',
  },
  ERROR_PURCHASE_DATE_PICKUP_AT: {
    ko: '출고예정일자를 변경해주세요',
    en: 'Please change the expected shipping date',
  },
  ERROR_PURCHASE_DATE_LDIP_PICKUP_AT: {
    ko: 'L/DIP 발송예상일자를 변경해주세요',
    en: 'Please change the L/DIP shipping date',
  },
  ERROR_PURCHASE_DATE_INVALID_DATE_RANGE: {
    ko: '오늘보다 이전 날짜는 선택할 수 없습니다',
    en: 'You cannot select a date earlier than today',
  },
  ERROR_PURCHASE_DATE_NO_INPUT: {
    ko: '예상일자를 입력해주세요.',
    en: 'Please enter the expected date.',
  },
  ERROR_SAMPLE_RANGE_EXCEEDS_BULK_RANGE: {
    ko: '샘플 최대 수량이 벌크 최소 수량보다 큽니다.',
    en: 'The maximum quantity of the sample is larger than the minimum quantity of the bulk.',
  },
  ERROR_CONFIRM_PASSWORD: {
    ko: '비밀번호가 일치하지 않습니다.',
    en: 'Passwords do not match.',
  },
  ERROR_SAME_PASSWORD: {
    ko: '기존 비밀번호와 동일하게 설정할 수 없습니다.',
    en: 'You cannot set the same password as the previous one.',
  },
  ERROR_SAME_DISCOUNT_OPTION: {
    ko: '할인가는 단가와 동일하거나 높게 설정할 수 없습니다.',
    en: 'The discount price cannot be set equal to or higher than the unit price.',
  },
  ERROR_SMALL_DISCOUNT_OPTION: {
    ko: '상품 단가의 50% 이하의 할인가는 입력이 불가합니다.',
    en: 'Discount price less than or equal to 50% of the product unit price is not allowed.',
  },
}

const auth = {
  LOGIN: {
    ko: '로그인',
    en: 'Login',
  },
  LOGOUT: {
    ko: '로그아웃',
    en: 'Logout',
  },
  KEEP_LOGGED_IN: {
    ko: '로그인 상태 유지',
    en: 'Keep me logged in',
  },
  ID: {
    ko: '아이디',
    en: 'ID',
  },
  PASSWORD: {
    ko: '비밀번호',
    en: 'Password',
  },
  SETTINGS_PASSWORD: {
    ko: '비밀번호 설정',
    en: 'Set Up Password',
  },
  SETTINGS_PASSWORD_DESC: {
    ko: '임시 비밀번호로 로그인 하셨네요.\n계정 보안을 위해 새 비밀번호를 설정해주세요',
    en: 'You have logged in with a temporary password.\nPlease set your new password.',
  },
  UPDATE_PASSWORD: {
    ko: '비밀번호 변경',
    en: 'Update Password',
  },
  PASSWORD_PLACEHOLDER: {
    ko: '영문, 숫자 포함 8 ~ 30자로 입력해주세요.',
    en: 'Enter 8 ~ 30 characters.(letters and numbers.)',
  },
  CONFIRM_PASSWORD: {
    ko: '비밀번호 확인',
    en: 'Confirm Password',
  },
  CONFIRM_PASSWORD_PLACEHOLDER: {
    ko: '비밀번호를 다시 입력해주세요.',
    en: 'Please enter your password again.',
  },
  AUTH_EXPIRED: {
    ko: '로그인 정보 만료로 로그아웃 되었습니다.',
    en: 'Your login information has expired and you have been logged out.',
  },
  ACCESS_DENIED: {
    ko: '권한이 없습니다.',
    en: 'You do not have permission.',
  },
}

const quality = {
  QUALITY_AD: {
    ko: '광고 자료',
    en: 'Ad',
  },
  QUALITY: {
    ko: '상품',
    en: 'Item',
  },
  QUALITY_NAME: {
    ko: '아이템 이름',
    en: 'Item Name',
  },
  QUALITY_CODE: {
    ko: '스와치온 상품코드',
    en: 'Item number',
  },
  SAMPLE_NOT_AVAILABLE: {
    ko: '샘플 제공 불가능',
    en: 'Sample not available',
  },
  USE_PRINT: {
    ko: '프린트 주문이 가능한가요?',
    en: 'Is custom print available for this item?',
  },
  USE_CUSTOM_DYE: {
    ko: '랩딥(B/T) 주문이 가능한가요?',
    en: 'Is custom dye available for this item?',
  },
  USE_RETAIL: {
    ko: '소매판매가 가능한가요?',
    en: 'Is retail sales available for this item?',
  },
  SELECT_PRINT_METHOD: {
    ko: '프린트 방식은 무엇인가요?',
    en: 'What is the printing method?',
  },
  SAMPLE_RECOMMENDED: {
    ko: '샘플서비스가 불가능한 아이템은 마케팅에서 제외되며, 판매가 제한적일 수 있습니다.',
    en: 'Items that are not eligible for sample service are excluded from marketing and may have limited sales.',
  },
  PRODUCT_CODE: {
    ko: '스와치온 상품코드',
    en: 'Product ID',
  },
  QUALITY_STATUS: {
    ko: '판매상태',
    en: 'Sales status',
  },
  QUALITY_PRICE_SURPLUS: {
    ko: '퀄리티 추가단가',
    en: 'Surcharge per yard',
  },
  QUALITY_EDIT_SUCCESS: {
    ko: '상품 정보가 수정되었습니다.',
    en: 'Product info saved.',
  },
  PRICE_SAVED: {
    ko: '가격 테이블이 수정되었습니다.',
    en: 'Price table saved.',
  },
  CUSTOMS_CLEARANCE: {
    ko: '통관',
    en: 'Customs Clearance',
  },
  PRODUCT_PRICE_SURPLUS: {
    ko: '컬러별 추가단가',
    en: 'Product Surcharge',
  },
  SAMPLE_UNAVAILABLE: {
    ko: '샘플 불가로 설정된 상품입니다',
    en: 'This product is not available for sample',
  },
  CUSTOM_DYE_UNAVAILABLE: {
    ko: '랩딥(B/T) 불가로 설정된 상품입니다',
    en: 'This product is not available for custom dye',
  },
  PRINT_UNAVAILABLE: {
    ko: '프린트 불가로 설정된 상품입니다',
    en: 'This product is not available for print',
  },
  RETAIL_UNAVAILABLE: {
    ko: '소매판매 불가로 설정된 상품입니다',
    en: 'This product is not available for retail',
  },
  PRICE_SURPLUS: {
    ko: '추가단가',
    en: 'Surcharge',
  },
  PRODUCT_STATUS: {
    ko: '프로덕트 판매상태',
    en: 'Sales status',
  },
  PAGES_QUALITY_MEDIAS: {
    ko: '영상 사진 자료',
    en: 'Videos/Images',
  },
  PAGES_QUALITY_SETTINGS_BASIC: {
    ko: '상품정보',
    en: 'Specification',
  },
  PAGES_QUALITY_SITE: {
    ko: '스와치온 상품페이지',
    en: 'SwatchOn Product Page',
  },
  ADD_QUALITY: {
    ko: '상품 추가 등록',
    en: 'Register More Items',
  },
  CONTENT_NAME: {
    ko: '혼용소재',
    en: 'Fiber kind',
  },
  CONTENT_RATIO: {
    ko: '혼용률',
    en: 'Ratio',
  },
  QUALITY_INFO_DETAIL: {
    ko: '상세 정보',
    en: 'Detail',
  },
  QUALITY_CATEGORY: {
    ko: '조직',
    en: 'Type',
  },
  QUALITY_PATTERN: {
    ko: '패턴',
    en: 'Pattern',
  },
  QUALITY_CONTENT: {
    ko: '혼용률',
    en: 'Composition',
  },
  QUALITY_WEIGHT: {
    ko: '중량',
    en: 'Weight',
  },
  QUALITY_WEIGHT_GSM: {
    ko: '중량 (gsm)',
    en: 'Weight (gsm)',
  },
  QUALITY_WEIGHT_G: {
    ko: '중량 (g/yd)',
    en: 'Weight (g/yd)',
  },
  QUALITY_MINIMUM_WIDTH: {
    ko: '가용폭 (in)',
    en: 'Width (in)',
  },
  QUALITY_FULL_WIDTH: {
    ko: '전폭 (in)',
    en: 'Full Width (in)',
  },
  QUALITY_YARN: {
    ko: '원사',
    en: 'Yarn',
  },
  QUALITY_YARN_SPEC: {
    ko: '원사 스펙',
    en: 'Yarn Spec',
  },
  QUALITY_YARN_DENSITY: {
    ko: '밀도/게이지',
    en: 'Yarn Density',
  },
  QUALITY_YARN_EFFECT: {
    ko: '원사 특성',
    en: 'Yarn Effect',
  },
  QUALITY_BRAND_YARN: {
    ko: '원사 브랜드',
    en: 'Yarn Brand',
  },
  QUALITY_DYE_METHOD: {
    ko: '염색방식',
    en: 'Piece Dye',
  },
  QUALITY_WEAVES: {
    ko: '제직',
    en: 'Weaves',
  },
  QUALITY_FINISH: {
    ko: '후가공',
    en: 'Finish',
  },
  QUALITY_PERFORMANCE: {
    ko: '기능성',
    en: 'Performance',
  },
  QUALITY_ORIGIN: {
    ko: '생산국가',
    en: 'Origin',
  },
  QUALITY_HS_CODE: {
    ko: 'HS코드',
    en: 'HS Code',
  },
  TEST_REPORTS: {
    ko: '검사리포트',
    en: 'Test Reports',
  },
  TRACKING_LINK: {
    ko: '배송추적링크',
    en: 'Tracking Link',
  },
  TEST_REPORTABLE: {
    ko: '테스트 진행가능',
    en: 'Test Reportable',
  },
  NON_TEST_REPORTABLE: {
    ko: '성적서 제공 불가',
    en: 'Non Test Reportable',
  },
  QUALITY_SUSTAINABILITY_FACTOR: {
    ko: '친환경',
    en: 'Sustainability',
  },
  QUALITY_SELLER_MEMO: {
    ko: '비고',
    en: 'Memo',
  },
  QUALITY_SELLER_MEMO_PLACEHOLDER: {
    ko: '상품에 관한 어떠한 내용이라도 좋으니 적어주세요. (복종, 마케팅 홍보 문구, 판매바이어, 어필하고 싶은 내용 등)\n스와치온 담당 MD가 참고하여 상품 노출에 힘쓰도록 하겠습니다.',
    en: 'Please write anything about the product. (Compliance, marketing and promotion phrases, sales buyers, appealing content, etc.)\nSwatchon MD will refer to it and make an effort to expose the product.',
  },
  QUALITY_SETTINGS_PRICE: {
    ko: '가격',
    en: 'Price',
  },
  QUALITY_PRODUCT_LIST: {
    ko: '컬러 리스트',
    en: 'Color List',
  },
  ORDER_AMOUNT: {
    ko: '주문수량',
    en: 'Order amount',
  },
  ORDER_AMOUNT_YD: {
    ko: '주문수량 (yd)',
    en: 'Quantity (yd)',
  },
  QUALITY_STATUS_PENDING: {
    ko: '판매대기',
    en: 'Pending',
  },
  QUALITY_STATUS_REGISTERING: {
    ko: '등록작업중',
    en: 'Registration in Progress',
  },
  QUALITY_STATUS_PUBLISHED: {
    ko: '판매중',
    en: 'On Sale',
  },
  QUALITY_STATUS_DROPPED: {
    ko: '판매임시중단',
    en: 'Sales Stop Scheduled',
  },
  QUALITY_STATUS_RETIRED: {
    ko: '판매중단',
    en: 'Not on Sale',
  },
  QUALITY_STATUS_MEDIA_COMPLETED: {
    ko: '촬영완료',
    en: 'Media Completed',
  },
  QUALITY_STATUS_REGISTER_CANCELLED: {
    ko: '등록취소',
    en: 'Register Cancelled',
  },
  QUALITY_STATUS_SETTING_CUSTOM_DYE: {
    ko: '정보입력중',
    en: 'Draft',
  },
  QUALITY_STATUS_SETTING_PRINT: {
    ko: '정보입력중',
    en: 'Drafting',
  },
  QUALITY_STATUS_SETTING_PRICE: {
    ko: '정보입력중',
    en: 'Drafting',
  },
  QUALITY_STATUS_SETTING_RETAIL: {
    ko: '정보입력중',
    en: 'Drafting',
  },
  QUALITY_STATUS_SETTING_COLOR: {
    ko: '정보입력중',
    en: 'Drafting',
  },
  QUALITY_STATUS_WRITING_DETAIL: {
    ko: '정보입력중',
    en: 'Drafting',
  },
  QUALITY_STATUS_WRITING_INFO: {
    ko: '정보입력중',
    en: 'Drafting',
  },
  CONTINUE_DRAFT: {
    ko: '입력계속하기',
    en: 'Continue Draft',
  },
  PRODUCT_STATUS_PENDING: {
    ko: '판매대기',
    en: 'Pending',
  },
  PRODUCT_STATUS_PUBLISHED: {
    ko: '판매중',
    en: 'Published',
  },
  PRODUCT_STATUS_RETIRED: {
    ko: '판매중단',
    en: 'Retired',
  },
  NEW_PRICE_RANGE_DESC_STANDARD_ORDER: {
    ko: `<ul>
      <li class="disc">이 제품의 가격정보를 입력해주세요.</li>
    </ul>`,
    en: `<ul>
      <li class="disc">Please provide information for this item.</li>
    </ul>`,
  },
  NEW_PRICE_RANGE_DESC_CUSTOM_DYE: {
    ko: `<ul>
      <li class="disc">이 제품의 랩딥(B/T) 도매 주문을 위한 가격정보를 입력해주세요.</li>
    </ul>`,
    en: `<ul>
      <li class="disc">Please provide information for the custom dye wholesale order of this item.</li>
    </ul>`,
  },
  NEW_PRICE_RANGE_DESC_PRINT: {
    ko: `<ul>
      <li class="disc">이 제품의 프린트 도매 주문을 위한 가격정보를 입력해주세요.</li>
    </ul>`,
    en: `<ul>
      <li class="disc">Please provide information for the print wholesale order of this item.</li>
    </ul>`,
  },
  NEW_PRICE_RANGE_DESC_RETAIL: {
    ko: `<ul>
      <li class="disc">이 제품의 소매 주문을 위한 가격정보를 입력해주세요.</li>
    </ul>`,
    en: `<ul>
      <li class="disc">Please provide information for the retail order of this item.</li>
    </ul>`,
  },
  SAMPLE_INSTRUCTION: {
    ko: `<ul>
      <li class="disc">이 제품에 대한 샘플 주문을 할 때 필요한 정보를 제공해주세요.</li>
      <li class="disc">샘플 수량은 20야드 이내에서 판매하는 것을 권장드리며, 일부 컬러만 샘플 가능하더라도 문제 없습니다.</li>
    </ul>`,
    en: `<ul>
      <li class="disc">Please provide information for the sample order of this item.</li>
      <li class="disc">We recommend selling samples within 20 yards, and it's OK if only some colors are available for samples.</li>
    </ul>`,
  },
  QUALITY_ADDED: {
    ko: '상품 등록 완료',
    en: 'Item registered',
  },
  QUALITY_ADDED_INST_1: {
    ko: '상품 <span class="c-primary f-700">%s</span>이(가) 등록되었습니다. 상품이 판매중이 아님을 유의해주세요.',
    en: 'Your item <span class="c-primary f-700">%s</span> has been registered. Please notice that the item is not on sale yet.',
  },
  QUALITY_ADDED_INST_2: {
    ko: `등록된 상품의 모든 컬러에 대해 <b>아이템명과 컬러코드를 부착하여 1야드 샘플</b>을 아래 주소로 발송해주세요. 샘플 야드지는 상품의 사진 및 영상 촬영, 스와치 샘플 서비스에 사용됩니다. <b>신규 샘플 발송 후, 샘플 분실을 방지하기 위해 MD팀에 발송 확인 메시지를 보내주세요.</b>

[보내실 주소]
경기 남양주 다산순환로 20 현대프리미어 캠퍼스몰 Gate2 D동 10층 6호
다산 물류센터 상품등록팀: 070-8983-9494 / md@swatchon.com`,
    en: `Please send <b>1 yard sample with item name and color code attached</b> for all colors of the registered product to the address below. The sample yardages will be used for product photos, videos, and swatch sample services. <b>After sending the new samples, please send a confirmation message to the MD team to prevent sample loss.</b>
    
[Address]
Room 6, 10th floor, Gate2 D, Hyundai Premier Campus Mall, Dasan Circulation Road 20, Namyangju, Gyeonggi
Dasan Logistics Center Product Registration Team: 070-8983-9494 / md@swatchon.com`,
  },
  QUALITY_ADDED_INST_3: {
    ko: '샘플 야드지가 수령되어 바이어 영업용 샘플 제작이 완료되면 상품이 판매중으로 전환됩니다.',
    en: 'Item sales will be started after the sample yardages are received and processed by our sourcing team.',
  },
}

const bulk = {
  BULK_DETAIL: {
    ko: '견적요청내역',
    en: 'Request Detail',
  },
  BULK_REGISTERED_PRICE: {
    ko: '아이템 등록가격',
    en: 'Registered price of the item',
  },
  BULK_QUOTE_BUYER_HISTORY: {
    ko: '전체견적내역',
    en: 'Quote history',
  },
  BULK_STATUS_REQUESTED: {
    ko: '견적요청',
    en: 'Requested',
  },
  BULK_STATUS_ACCEPTED: {
    ko: '견적완료',
    en: 'Accepted',
  },
  BULK_STATUS_CANCELLED: {
    ko: '견적취소',
    en: 'Cancelled',
  },
  BULK_STATUS_COMPLETED: {
    ko: '견적만료',
    en: 'Completed',
  },
  BULK_QUANTITY: {
    ko: '컬러당 주문수량',
    en: 'Qty. per color',
  },
  BULK_TOTAL_AMOUNT: {
    ko: '총 주문수량',
    en: 'Total Qty.',
  },
  BULK_COUNTRY: {
    ko: '도착 국가',
    en: 'Dest. country',
  },
  BULK_CITY: {
    ko: '도착 도시',
    en: 'Dest. city',
  },
  BULK_CODE: {
    ko: '견적번호',
    en: 'Quote number',
  },
  BULK_ACCEPTED_AT: {
    ko: '회신일자',
    en: 'Replied at',
  },
  BULK_CONFIRM_SUCCESS: {
    ko: '견적확정이 완료되었습니다.',
    en: 'The bulk request has been confirmed.',
  },
  BULK_STATUS: {
    ko: '견적상태',
    en: 'Status',
  },
  BULK_PAYLOAD: {
    ko: '견적서 작성',
    en: 'Write a quote',
  },
  BULK_QUOTE_RESULT: {
    ko: '견적결과',
    en: 'Quote Result',
  },
  BULK_QUOTE_RESULT_PRICE: {
    ko: '견적단가',
    en: 'Quote Price',
  },
  BULK_QUOTE_EXPIRED_AT: {
    ko: '견적유효일자',
    en: 'Valid Until',
  },
  BULK_SHIPPING_FEE: {
    ko: '배송비',
    en: 'Shipping Fee',
  },
  BULK_SHIPPING_FEE_LATER: {
    ko: '현재 확정 불가, 추후 알림',
    en: 'Currently unavailable, will notify later',
  },
  BULK_QUOTE_LEAD_TIME: {
    ko: '예상납기일수',
    en: 'Lead Time',
  },
  BULK_ORDER: {
    ko: '전환주문',
    en: 'Converted Orders',
  },
  BULK_CONFIRM: {
    ko: '견적 내역 확정',
    en: 'Confirm Quote',
  },
  BULK_CONFIRM_TEXT: {
    ko: '견적내역을 확정하고 바이어에게 고지하시겠습니까?',
    en: 'Confirm the quote and notify the buyer?',
  },
  NO_ORDER: {
    ko: '주문 없음',
    en: 'No Order',
  },
}

const purchase = {
  PURCHASE_LIST_LDIP_NEED: {
    ko: 'L/DIP 발송예정목록',
    en: 'L/DIP Needed',
  },
  PURCHASE_NEW_PURCHASES: {
    ko: '새로운 주문 %s',
    en: '%s New Purchases',
  },
  PURCHASED_AT: {
    ko: '주문일자',
    en: 'Order date',
  },
  PURCHASE_DATE_SAVED_PICKUP_AT: {
    ko: '%s개 주문상품의 출고예상일자가 수정되었습니다.',
    en: 'Pickup dates for %s items have been saved.',
  },
  PURCHASE_DATE_SAVED_LDIP_PICKUP_AT: {
    ko: '%s개 주문상품의 L/DIP 발송예상일자가 수정되었습니다.',
    en: 'L/DIP Pickup dates for %s items have been saved.',
  },
  CONFIRM_PURCHASE: {
    ko: '주문확정',
    en: 'Confirm Purchase',
  },
  CONFIRM_PURCHASE_PLEASE_CHECK: {
    ko: '입력하신 주문처리내역을 확인해주세요.',
    en: 'Please check the order processing information you entered.',
  },
  CONFIRM_PURCHASE_FINAL: {
    ko: '위 내용으로 주문을 확정하시겠습니까?',
    en: 'Are you sure you want to confirm the order with the above information?',
  },
  PURCHASE_PROCESSING: {
    ko: '주문처리',
    en: 'Purchase Processing',
  },
  DUE_LEAD_TIME: {
    ko: '출고기한',
    en: 'Ship-out due',
  },
  DUE_LEAD_TIME_EXPIRED: {
    ko: '수락처리기간이 만료되었습니다. 주문취소로 처리 부탁드립니다.',
    en: 'Leadtime due has expired. Please cancel the order.',
  },
  LDIP_PICKUP_AT: {
    ko: 'L/DIP 발송예상일자',
    en: 'L/DIP Pickup At',
  },
  LDIP_MD_ADDRESS: {
    ko: 'L/DIP 발송주소',
    en: 'L/DIP Address',
  },
  LDIP_MD_ADDRESS_DESC: {
    ko: 'Lab Dip의 택배 발송은 아래 MD 담당자 사무실로 발송 부탁드립니다.',
    en: 'Please send the Lab Dip to the MD office below.',
  },
  ESTIMATED_PICKUP_AT: {
    ko: '상품픽업예정',
    en: 'Estimated Pickup Date',
  },
  EXPECTED_SHIPOUT_DATE: {
    ko: '출고예정일자',
    en: 'Expected ship-out date',
  },
  PICKUP_AT: {
    ko: '출고예상일자',
    en: 'Pickup At',
  },
  PICKUP_AT_QUICK: {
    ko: '퀵 픽업일자',
    en: 'Quick Pickup At',
  },

  DELIVERY_METHOD: {
    ko: '출고배송방법',
    en: 'Delivery method',
  },
  DELIVERY_METHOD_DDM: {
    ko: '동대문 종합시장 픽업',
    en: 'Pick up at DDM market',
  },
  DELIVERY_METHOD_LOGISTICS: {
    ko: '직접 발송',
    en: 'Deliver to SwatchOn warehouse',
  },
  LOGISTICS_COMPANY: {
    ko: '출고업체',
    en: 'Logistics company',
  },
  LOGISTICS_COMPANY_KK: {
    ko: '경기택배',
    en: 'Gyeonggi Logistics',
  },
  LOGISTICS_COMPANY_KD: {
    ko: '경동택배',
    en: 'Gyeongdong Logistics',
  },
  LOGISTICS_COMPANY_IS: {
    ko: '일신택배',
    en: 'Ilshin Logistics',
  },
  LOGISTICS_COMPANY_ETC: {
    ko: '기타택배',
    en: 'Other Logistics',
  },
  LOGISTICS_COMPANY_QUICK_TRUCK: {
    ko: '퀵(용달)',
    en: 'Self Quick',
  },
  PURCHASE_IS_SENT: {
    ko: '출고여부',
    en: 'Sent?',
  },
  PURCHASE_IS_SENT_TRUE: {
    ko: '출고완료',
    en: 'Sent',
  },
  PURCHASE_IS_SENT_FALSE: {
    ko: '출고전',
    en: 'Not Sent',
  },
  PURCHASE_IS_LDIP_SENT: {
    ko: 'L/DIP 발송여부',
    en: 'L/DIP Sent',
  },
  PURCHASE_IS_LDIP_SENT_TRUE: {
    ko: '발송완료',
    en: 'Sent',
  },
  PURCHASE_IS_LDIP_SENT_FALSE: {
    ko: '발송 전',
    en: 'Not Sent',
  },
  PURCHASE_STATUS_REQUESTED: {
    ko: '신규',
    en: 'Requested',
  },
  PURCHASE_STATUS_DELIVERY_REQUESTED: {
    ko: '납품대기',
    en: 'Delivery Requested',
  },
  PURCHASE_STATUS_LDIP_REQUESTED: {
    ko: 'L/DIP 대기',
    en: 'L/DIP Requested',
  },
  PURCHASE_STATUS_LDIP_CONFIRMING: {
    ko: 'L/DIP 확인중',
    en: 'L/DIP Confirming',
  },
  PURCHASE_STATUS_UNDER_QC: {
    ko: '검수중',
    en: 'Under QC',
  },
  PURCHASE_STATUS_INSPECT_COMPLETED: {
    ko: '입고완료',
    en: 'Inspect Completed',
  },
  PURCHASE_STATUS_SHIPPING: {
    ko: '바이어 배송중',
    en: 'Shipping',
  },
  PURCHASE_STATUS_DELIVERED: {
    ko: '바이어 배송완료',
    en: 'Delivered',
  },
  PURCHASE_STATUS_CANCELLED: {
    ko: '취소',
    en: 'Cancelled',
  },
  PURCHASE_STATUS_FAILED: {
    ko: '취소',
    en: 'Cancelled',
  },
  PURCHASE_CONFIRM: {
    ko: '출고확인',
    en: 'Purchase Confirmation',
  },
  PURCHASE_CANCELLED: {
    ko: '주문취소',
    en: 'Purchase Cancelled',
  },
  PURCHASE_FAILURE_REASON: {
    ko: '취소사유',
    en: 'Cancelation reason',
  },
  DELAYED: {
    ko: '납기지연',
    en: 'Delayed',
  },
  OUT_OF_STOCK: {
    ko: '재고부족',
    en: 'Out of stock',
  },
  PRINT_OUT_OF_STOCK: {
    ko: '주문수량 납품불가',
    en: 'Print out of stock',
  },
  PRINT_NOT_AVAILABLE: {
    ko: '프린트생산불가',
    en: 'Print not available',
  },
  PRODUCTION_TERMINATED: {
    ko: '생산종료',
    en: 'Production terminated',
  },
  PRODUCTION_NOT_AVAILABLE: {
    ko: '생산불가',
    en: 'Unable to produce new batch',
  },
  REASON_PRODUCTION_NOT_AVAILABLE: {
    ko: '생산불가사유',
    en: 'Tell us why it is unable to produce new batch.',
  },
  REASON_PRINT_NOT_AVAILABLE: {
    ko: '프린트불가사유',
    en: 'Tell us why the print is not available.',
  },
  CANCEL_PURCHASE_WARNING: {
    ko: '해당 주문은 <span class="c-danger">주문취소</span> 처리되며, 바이어 결정에 따라 재주문될 수 있습니다.',
    en: 'This order will be <span class="c-danger">cancelled</span> and may be reordered depending on the buyer’s decision.',
  },
  UNIT_PRICE_RISEN: {
    ko: '단가상승',
    en: 'Unit price risen',
  },
  UNIT_PRICE_RISEN_WARNING: {
    ko: '<span class="c-danger">주문 처리하신 후, 상품관리 메뉴에서 가격 수정</span> 부탁드립니다. 단가 변동으로 인한 주문취소가 지속될 경우 판매에 악영향을 미칠 수 있습니다.',
    en: '<span class="c-danger">Please change the price in the product management menu after processing the order.</span> If the order cancellation continues due to price changes, it may have a negative impact on sales.',
  },
  UNIT_PRICE_RISEN_LABEL: {
    ko: '현재 판매 단가를 입력해 주세요',
    en: 'Please enter the current unit price',
  },
  CURRENT_STOCK: {
    ko: '현재재고수량',
    en: 'Current Stock quantity',
  },
  PRODUCABLE_QUANTITY_PRODUCTION: {
    ko: '생산가능수량',
    en: 'Producable Quantity',
  },
  PRODUCABLE_QUANTITY_PRINT: {
    ko: '프린트가능수량',
    en: 'Printable Quantity',
  },
  UNKNOWN: {
    ko: '확인필요',
    en: 'Unknown',
  },
  PICKUP_MUST_BE_PREPARED_UNTIL: {
    ko: '상품을 픽업하기 위해 판매자님의 동대문매장에 퀵 방문이 예정되어 있습니다. 꼭 %s까지 상품을 준비해주세요.',
    en: `Your quick visit to the seller's Dongdaemun store is scheduled to pick up the product. Please prepare the product by %s.`,
  },
  PICKUP_POSSIBLE_BEFORE_3: {
    ko: '당일픽업은 오후 3시 이전 확정 건까지 가능합니다.',
    en: 'Same-day pickup is available until 3 p.m.',
  },
  QUANTITY_STOCK: {
    ko: '현재 재고수량',
    en: 'Current stock',
  },
  QUANTITY_PRODUCABLE: {
    ko: '생산 가능수량',
    en: 'Producable quantity',
  },
  QUANTITY_PRINTABLE: {
    ko: '프린트 가능수량',
    en: 'Printable quantity',
  },
  CHANGE_LDIP_PICKUP_AT: {
    ko: 'L/DIP 발송예정일 변경',
    en: 'Change L/DIP Pickup Date',
  },
  CHANGE_DELIVERY_METHOD: {
    ko: '출고배송방법 변경',
    en: 'Change Delivery Method',
  },
  DELIVERY_TO: {
    ko: '출고배송지',
    en: 'Delivery to',
  },
  QUICK_PICKUP_TIME_DESC: {
    ko: '매일 오후 3시 - 4시 픽업',
    en: 'Available from 4pm to 5pm every day.',
  },
  DELIVERY_TO_DESC: {
    ko: '출고물품의 택배발송은 아래 담당 물류센터로 발송 부탁드립니다.',
    en: 'Please send the delivery items to the logistics center below.',
  },
  PRINT_DELIVERY_NOTE: {
    ko: '출고장 출력',
    en: 'Print Delivery Note',
  },
  SETTLEMENT_CONFIRM: {
    ko: '정산확인',
    en: 'Settlement Confirmation',
  },
  SETTLEMENT_NOT_CONFIRMED: {
    ko: '미정',
    en: 'Not Confirmed',
  },
}

const stockCheckRequest = {
  STOCK_CHECK_REQUEST_CODE: {
    ko: '요청번호',
    en: 'Request number',
  },
  STOCK_CHECK_NEW_REQUESTS: {
    ko: '새로운 요청 %s',
    en: '%s New Requests',
  },
  REQUESTED_QUANTITY: {
    ko: '고객필요수량',
    en: 'Requested Quantity',
  },
  REPRODUCTION_AVAILABILITY: {
    ko: '생산가능여부',
    en: 'Reproduction Availability',
  },
  HAS_STOCK: {
    ko: '재고 있음',
    en: 'Has stock',
  },
  OUT_OF_STOCK: {
    ko: '재고 부족',
    en: 'Out of stock',
  },
  OUT_OF_STOCK_DESC: {
    ko: '주문수량 납품 불가',
    en: 'Not enough stock',
  },
  REPRODUCTION_QUANTITY: {
    ko: '최소주문수량',
    en: 'Reproduction Quantity',
  },
  REPRODUCTION_DAYS: {
    ko: '예상납기',
    en: 'Leadtime',
  },
  CHECK_STOCK: {
    ko: '재고확인',
    en: 'Check Stock',
  },
  REPRODUCTION_AVAILABILITY_AVAILABLE: {
    ko: '가능',
    en: 'Available',
  },
  REPRODUCTION_AVAILABILITY_UNAVAILABLE: {
    ko: '불가능 (재고 소진 후 종료)',
    en: 'Unavailable (End after stock depletion)',
  },
  REPRODUCTION_AVAILABILITY_UNSPECIFIED: {
    ko: '미정',
    en: 'Unspecified',
  },
  REPRODUCTION_AVAILABILITY_ALWAYS_ON_STOCK: {
    ko: '항시 진행 컬러',
    en: 'Always on stock',
  },
  UNREPRODUCIBLE_RANGE: {
    ko: '해당 컬러만 생산 불가능인가요?',
    en: 'Is it impossible to produce only that color?',
  },
  UNREPRODUCIBLE_RANGE_ONLY_THIS_COLOR_BUTTON: {
    ko: '네, 해당 컬러만 생산 불가능입니다.',
    en: 'Yes, only that color cannot be produced.',
  },
  UNREPRODUCIBLE_RANGE_ONLY_THIS_COLOR: {
    ko: '해당 컬러만 생산 불가능',
    en: 'Only that color cannot be produced',
  },
  UNREPRODUCIBLE_RANGE_ALL_COLORS_BUTTON: {
    ko: '아니요, 모든 컬러가 생산 불가능입니다.',
    en: 'No, all colors cannot be produced.',
  },
  UNREPRODUCIBLE_RANGE_ALL_COLORS: {
    ko: '모든 컬러가 생산 불가능',
    en: 'Not all colors can be produced',
  },
  STOCK_CHECK_PRODUCTS: {
    ko: '재고요청상품',
    en: 'Stock Check Products',
  },
  STOCK_CHECK_REQUEST_EXPIRED: {
    ko: '재고확인 요청일부터 5일이 지나 입력 기한이 만료되었습니다.',
    en: 'The deadline for input has expired after 5 days from the date of stock confirmation request.',
  },
  STOCK_CHECK_REQUEST_COMPLETE_ALL: {
    ko: '모든 상품에 대해 재고입력 처리를 완료해주세요.',
    en: 'Please complete the inventory input for all products.',
  },
  STOCK_CHECK_REQUEST_STATUS_REQUESTED: {
    ko: '신규',
    en: 'Requested',
  },
  STOCK_CHECK_REQUEST_STATUS_CONFIRMED: {
    ko: '입력완료',
    en: 'Confirmed',
  },
  STOCK_CHECK_REQUEST_STATUS_CANCELLED: {
    ko: '만료',
    en: 'Cancelled',
  },
  STOCK_CHECK_REQUEST_STATUS_COMPLETED: {
    ko: '입력완료',
    en: 'Completed',
  },
  STOCK_CHECK_EXPIRED_DESC: {
    ko: '재고확인 요청일부터 5일이 지나 입력 기한이 만료되었습니다.',
    en: 'The deadline for input has expired after 5 days from the date of stock confirmation request.',
  },
}

const settlement = {
  SETTLEMENT_ID: {
    ko: '정산입금번호',
    en: 'Settlement ID',
  },
  SETTLED_AT: {
    ko: '정산일자',
    en: 'Settled At',
  },
  SETTLEMENT_CONFIRMED_AT: {
    ko: '거래일자',
    en: 'Confirmed At',
  },
  SUPPLY_AMOUNT: {
    ko: '공급가액',
    en: 'Supply Amount',
  },
  NUM_SETTLEMENT_ITEMS: {
    ko: '정산건수',
    en: 'No. of Settlement Items',
  },
  EXCHANGE_RATE: {
    ko: '적용환율',
    en: 'Exchange Rate',
  },
  SETTLEMENT_ITEMS: {
    ko: '정산대상목록',
    en: 'Settlement Items',
  },
  SETTLEMENT_DEPOSITS: {
    ko: '정산입금목록',
    en: 'Settlement Deposits',
  },
  SETTLEMENT_AMOUNT: {
    ko: '입금금액',
    en: 'Amount',
  },
  SETTLEMENT_BANK_ACCOUNT: {
    ko: '입금계좌',
    en: 'Bank Account',
  },
  SETTLEMENT_COMPLETED_AT: {
    ko: '입금일자',
    en: 'Completed At',
  },
  SETTLEMENT_STATUS: {
    ko: '정산상태',
    en: 'Settlement Status',
  },
  SETTLEMENT_STATUS_COMPLETED: {
    ko: '입금완료',
    en: 'Completed',
  },
  SETTLEMENT_STATUS_PENDING: {
    ko: '정산대기',
    en: 'Pending',
  },
  SETTLEMENT_STATUS_CONFIRMED: {
    ko: '정산계상',
    en: 'Confirmed',
  },
  SETTLEMENT_STATUS_SETTLED: {
    ko: '정산완료',
    en: 'Settled',
  },
  SETTLEMENT_STATUS_CANCELLED: {
    ko: '-',
    en: '-',
  },
  FOR_N_SETTLEMENTS: {
    ko: '총 <span class="c-primary">%s</span>건의 정산입금건에 대하여',
    en: 'For <span class="c-primary">%s</span> settlements',
  },
}

const vatReceipt = {
  VAT_RECEIPT_TOTAL_AMOUNT: {
    ko: '총 발행내역',
    en: 'Total VAT Receipt',
  },
  VAT_RECEIPT_INFO: {
    ko: '세금계산서 자료',
    en: 'VAT Receipt Info',
  },
  VAT_RECEIPT_ISSUE_ID: {
    ko: '세금계산서 승인번호',
    en: 'VAT Receipt Issue ID',
  },
  VAT_RECEIPT_STATUS: {
    ko: '세금계산서',
    en: 'VAT Receipt',
  },
  VAT_RECEIPT_STATUS_PENDING: {
    ko: '없음',
    en: 'None',
  },
  VAT_RECEIPT_STATUS_UPLOADED: {
    ko: '수신완료',
    en: 'Uploaded',
  },
  VAT_RECEIPT_STATUS_NOT_NEEDED: {
    ko: '-',
    en: '-',
  },
  VAT: {
    ko: '부가세액',
    en: 'VAT',
  },
  VAT_RECEIPT: {
    ko: '세금계산서',
    en: 'VAT Receipt',
  },
  VAT_RECEIPT_ID: {
    ko: '세금계산서 번호',
    en: 'Tax Invoice ID',
  },
  VAT_RECEIPT_CREATED_AT: {
    ko: '발행일',
    en: 'Published At',
  },
  VAT_RECEIPT_NEEDED: {
    ko: '세금계산서 발행대상',
    en: 'Tax Invoice Needed',
  },
  VAT_RECEIPT_ISSUE_ID_TODO: {
    ko: '승인번호 숫자 24자리를 입력해주세요.',
    en: 'Please enter a 24-digit approval number.',
  },
  UPLOAD_VAT_RECEIPT: {
    ko: '세금계산서 등록',
    en: 'Upload VAT receipt',
  },
  UPLOAD_VAT_RECEIPT_TODO: {
    ko: '수기세금계산서는 계산서 이미지를 업로드해주세요.',
    en: 'Please upload the invoice image for manual tax invoice.',
  },
  MODAL_CONFIRM_VAT_RECEIPT_AMOUNT: {
    ko: '세금계산서 발행금액을 정확히 확인하셨나요?',
    en: 'Have you checked the amount of the invoice?',
  },
  VAT_RECEIPT_UPLOADED: {
    ko: '세금계산서가 업로드되었습니다.',
    en: 'The invoice has been uploaded.',
  },
}

const settings = {
  VIEW_404: {
    ko: '페이지를 찾을 수 없습니다.',
    en: 'Page not found.',
  },
  VIEW_404_DESC: {
    ko: `페이지의 주소가 잘못 입력 되었거나\n주소의 변경 혹은 삭제로 인해 사용하실 수 없습니다.\n입력하신 페이지의 주소가 정확한지 확인해주세요`,
    en: `The address of the page is incorrectly entered\nThe address has been changed or deleted.\nPlease check if the address you entered is correct.`,
  },
  BASIC_INFO: {
    ko: '기본정보',
    en: 'Basic Information',
  },
  SETTINGS_BUSINESS_INFO: {
    ko: '사업자 정보 설정',
    en: 'Set Up Business Information',
  },
  SETTINGS_BUSINESS_INFO_DESC: {
    ko: '스와치온과 함께 해주셔서 감사합니다.\n셀러 계정을 설정하기 위해 사업자 정보를 입력해주세요.',
    en: 'Thank you for joining SwatchOn.\nPlease provide your business information to set up your seller account.',
  },
  SETTINGS_NOTIFICATION: {
    ko: '알림',
    en: 'Notification',
  },
  SETTINGS_NOTIFICATION_EDIT: {
    ko: '알림 설정',
    en: 'Notification Settings',
  },
  SETTINGS_NOTIFICATION_GUIDE: {
    ko: '주문 또는 견적 등 바이어로부터 들어온 문의사항에 대해 알림을 보내드립니다.',
    en: 'We send notifications for inquiries from buyers such as orders or quotes.',
  },
  SETTINGS_NOTIFICATION_GUIDE_ADDITIONAL: {
    ko: '알림을 수신할 연락처를 설정해주세요.',
    en: 'Please set the contact information to receive notifications.',
  },
  SETTLEMENT_COUNTRY_INFO: {
    ko: '정산계좌 소재지',
    en: 'Settlement Account Location',
  },
  SETTLEMENT_COUNTRY: {
    ko: '정산 국가',
    en: 'Settlement Country',
  },
  SETTLEMENT_BUSINESS_NUMBER: {
    ko: '사업자번호',
    en: 'Business Number',
  },
  CONTACT_INFO: {
    ko: '담당자',
    en: 'Contact',
  },
  CONTACT_NAME: {
    ko: '성함',
    en: 'Contact name',
  },
  CONTACT_JOB_TITLE: {
    ko: '직함',
    en: 'Job Title',
  },
  SELECT_COUNTRY: {
    ko: '국가 선택',
    en: 'Select Country',
  },
  PHONE: {
    ko: '전화',
    en: 'Phone',
  },
  MOBILE_PHONE: {
    ko: '핸드폰',
    en: 'Phone',
  },
  NO_REGISTERED_CONTACT: {
    ko: '등록된 연락처가 없습니다.',
    en: 'No registered contact.',
  },
  NO_REGISTERED_EMAIL: {
    ko: '등록된 이메일 주소가 없습니다.',
    en: 'No registered email address.',
  },
  PARTNER_INFO: {
    ko: '파트너 정보',
    en: 'Partner Info',
  },
  PARTNER_INFO_GUIDE: {
    ko: '사업자 정보 및 계좌정보는 직접 수정이 불가합니다. 수정을 원하실 경우, <a href="http://pf.kakao.com/_xfHxndC/chat" class="link" target="_blank" rel="noopener noreferrer">스와치온 카카오채널</a>로 요청해주세요.',
    en: 'Business and account information cannot be edited directly. If you want to edit, please contact <a href="http://pf.kakao.com/_xfHxndC/chat" class="link" target="_blank" rel="noopener noreferrer">Swatchon Kakao Channel</a>',
  },
  BUSINESS_INFO: {
    ko: '사업자정보',
    en: 'Business Info',
  },
  SETTLEMENT_TIN: {
    ko: '정산국가 기준 사업자번호',
    en: 'Business Number (Settlement Country)',
  },
  SETTLEMENT_ACCOUNT: {
    ko: '정산계좌',
    en: 'Settlement Account',
  },
  STORE_NAME: {
    ko: '상호명',
    en: 'Business Name',
  },
  STORE_NAME_EN: {
    ko: '상호명 (영문)',
    en: 'Business Name (English)',
  },
  BUSINESS_NUMBER: {
    ko: '사업자번호',
    en: 'Tax Identification Number',
  },
  BANK: {
    ko: '은행',
    en: 'Bank',
  },
  ACCOUNT_HOLDER: {
    ko: '예금주',
    en: 'Account holder',
  },
  ACCOUNT_NUMBER: {
    ko: '계좌번호',
    en: 'Account number',
  },
}

const contactUs = {
  BUSINESS_REGISTRATION_INFO: {
    ko: '사업자등록정보',
    en: 'Business Registration Info',
  },
  BUSINESS_NAME: {
    ko: '상호',
    en: 'Name',
  },
  BUSINESS_REGISTRATION_NO: {
    ko: '사업자등록번호',
    en: 'Business Registration Number',
  },
  BUSINESS_REGISTRATION: {
    ko: '사업자등록증',
    en: 'Business Registration',
  },
  TAX_EMAIL: {
    ko: '세금계산서\n발행 이메일',
    en: 'Tax Invoice\nEmail',
  },
  LOGISTICS_INFO: {
    ko: '물류센터 정보',
    en: 'Logistics Info',
  },
  LOGISTICS_MAP: {
    ko: '약도',
    en: 'Map',
  },
  POSITION: {
    ko: '직책',
    en: 'Position',
  },
  MD_ADDRESS: {
    ko: '사무실 주소',
    en: 'Office Address',
  },
  YOUR_MANAGER: {
    ko: '파트너 문의사항',
    en: 'Partner Contact',
  },
  KAKAO_ID: {
    ko: '카카오톡 채널 아이디',
    en: 'Kakao Channel ID',
  },
  PRODUCT_MANAGER: {
    ko: '상품 관리 담당자',
    en: 'Product Manager',
  },
  ORDER_MANAGER: {
    ko: '주문 ∙ 발주 ∙ 정산 관리 담당자',
    en: 'Order ∙ Purchase ∙ Settlement Manager',
  },
}

const dashboards = {
  STORE_BEST_SELLING_COUNTRIES: {
    ko: '국가별 매출분석',
    en: 'Sales by Country',
  },
  STORE_BEST_SELLING_QUALITIES: {
    ko: '상품별 매출분석',
    en: 'Sales by Item',
  },
  SWATCHON_BEST_SELLING_CATEGORIES: {
    ko: '스와치온 베스트셀러',
    en: 'SwatchOn Bestseller',
  },
  SWATCHON_BEST_SELLING_QUALITIES: {
    ko: '스와치온 베스트셀러',
    en: 'SwatchOn Bestseller',
  },
  RANGE_TYPE: {
    ko: '기간',
    en: 'Period',
  },
  SWATCH_CNT: {
    ko: '스와치 주문 건수',
    en: 'No. of Swatches',
  },
  FABRIC_CNT: {
    ko: '야드지 주문 건수',
    en: 'No. orders',
  },
  FABRIC_QUANTITY: {
    ko: '야드지 주문 수량',
    en: 'Order qty.',
  },
  FABRIC_REVENUE_KRW: {
    ko: '매출',
    en: 'Revenue(KRW)',
  },
  COUNTRY_CODE: {
    ko: '국가코드',
    en: 'Country code',
  },
  RANK: {
    ko: '순위',
    en: 'Rank',
  },
}

const common = {
  SWATCHON: {
    ko: '스와치온',
    en: 'SwatchOn',
  },
  SWATCHON_LOGISTICS: {
    ko: '스와치온 물류센터',
    en: 'SwatchOn Logistics',
  },
  SWATCHON_LOGISTICS_DASAN: {
    ko: '스와치온 다산 물류센터',
    en: 'SwatchOn Dasan Logistics',
  },
  SWATCHON_LOGISTICS_SUNGSU: {
    ko: '스와치온 성수 물류센터',
    en: 'SwatchOn Sungsu Logistics',
  },
  BASE_ADDRESS_DASAN: {
    ko: '경기도 남양주시 다산순환로 20\n현대프리미어캠퍼스몰 GATE 2\nD동 10층 6호',
    en: 'D-dong 10th floor, 20 Dasan Shunhwan-ro, Namyangju-si, Gyeonggi-do, Republic of Korea',
  },
  BASE_ADDRESS_SUNGSU: {
    ko: '서울시 성동구 광나루로 286\n아인빌딩 6층',
    en: '6th floor, 286 Gwangnaru-ro, Seongdong-gu, Seoul, Republic of Korea',
  },
  CENTER_ADDRESS_DASAN: {
    ko: '경기도 남양주시 다산순환로 20\n현대프리미어캠퍼스몰 GATE 2\nD동 10층 6호\n스와치온 다산 물류센터',
    en: 'Swatchon Dasan Distribution Center, D-dong 10th floor, 20 Dasan Shunhwan-ro, Namyangju-si, Gyeonggi-do, Republic of Korea',
  },
  CENTER_ADDRESS_SUNGSU: {
    ko: '서울시 성동구 광나루로 286\n아인빌딩 6층\n스와치온 성수 물류센터',
    en: 'Swatchon Sungsu Distribution Center, 6th floor, 286 Gwangnaru-ro, Seongdong-gu, Seoul, Republic of Korea',
  },
  SOURCING_MANAGER: {
    ko: '소싱 매니저',
    en: 'Sourcing Manager',
  },
  INCOTERMS: {
    ko: '인코텀즈',
    en: 'Incoterms',
  },
  LEAVE: {
    ko: '나가기',
    en: 'Leave',
  },
  YES: {
    ko: '예',
    en: 'Yes',
  },
  NO: {
    ko: '아니오',
    en: 'No',
  },
  STAY: {
    ko: '머무르기',
    en: 'Stay',
  },
  GO_BACK: {
    ko: '이전',
    en: 'Go Back',
  },
  INPUT_COMPLETED: {
    ko: '입력 완료하기',
    en: 'Complete Input',
  },
  MANAGER: {
    ko: '담당자',
    en: 'Manager',
  },
  FRONT: {
    ko: '앞',
    en: 'Front',
  },
  CENTER: {
    ko: '중앙',
    en: 'Center',
  },
  BACK: {
    ko: '뒤',
    en: 'Back',
  },
  ORDERED_COLOR: {
    ko: '주문 컬러',
    en: 'Ordered Color',
  },
  SAVE: {
    ko: '저장',
    en: 'Save',
  },
  SAVED: {
    ko: '저장되었습니다.',
    en: 'Saved.',
  },
  WIDTH: {
    ko: '너비',
    en: 'Width',
  },
  HEIGHT: {
    ko: '높이',
    en: 'Height',
  },
  DPI: {
    ko: '해상도',
    en: 'DPI',
  },
  PIXEL_SIZE: {
    ko: '픽셀 사이즈',
    en: 'Pixel Size',
  },
  ACTUAL_SIZE: {
    ko: '실측 사이즈',
    en: 'Actual Size',
  },
  ADD: {
    ko: '추가',
    en: 'Add',
  },
  AUTOCOMPLETE: {
    ko: '자동입력',
    en: 'Autocomplete',
  },
  UPLOAD_FILE: {
    ko: '파일 업로드',
    en: 'Upload File',
  },
  CHANGE_FILE: {
    ko: '파일 변경',
    en: 'Change File',
  },
  OPTIONAL: {
    ko: '선택',
    en: 'Optional',
  },
  NEXT: {
    ko: '다음',
    en: 'Next',
  },
  CANCEL: {
    ko: '취소',
    en: 'Cancel',
  },
  ACCEPT: {
    ko: '수락',
    en: 'Accept',
  },
  ALL: {
    ko: '전체',
    en: 'All',
  },
  LESS_THAN_MB: {
    ko: '%sMB 이하',
    en: 'Less than %sMB',
  },
  ON_OFF: {
    ko: '운영여부',
    en: 'On/Off',
  },
  ON: {
    ko: '운영',
    en: 'On',
  },
  OFF: {
    ko: '미운영',
    en: 'Off',
  },
  UPLOAD: {
    ko: '업로드',
    en: 'Upload',
  },
  DELETE: {
    ko: '삭제',
    en: 'Delete',
  },
  REMOVE: {
    ko: '삭제',
    en: 'Remove',
  },
  BASIC: {
    ko: '기본',
    en: 'Basic',
  },
  PROCESS: {
    ko: '가공',
    en: 'Process',
  },
  DETAIL: {
    ko: '상세',
    en: 'Detail',
  },
  PRICE: {
    ko: '가격',
    en: 'Price',
  },
  PRINT: {
    ko: '커스텀프린트',
    en: 'Custom Print',
  },
  COMPLETE: {
    ko: '완료',
    en: 'Complete',
  },
  CUSTOM_DYE: {
    ko: '랩딥(B/T)',
    en: 'Custom Dye',
  },
  RETAIL: {
    ko: '소매가',
    en: 'Retail',
  },
  SETTING: {
    ko: '설정',
    en: 'Setting',
  },
  ETC: {
    ko: '기타',
    en: 'Etc',
  },
  UNKNOWN: {
    ko: '미확인',
    en: 'Unknown',
  },
  NAME: {
    ko: '이름',
    en: 'Name',
  },
  EMAIL: {
    ko: '이메일',
    en: 'Email',
  },
  ADDRESS: {
    ko: '주소',
    en: 'Address',
  },
  CONTACT: {
    ko: '연락처',
    en: 'Contact',
  },
  SEARCH: {
    ko: '검색',
    en: 'Search',
  },
  CONFIRM: {
    ko: '확정하기',
    en: 'Confirm',
  },
  TOTAL: {
    ko: '총',
    en: 'Total',
  },
  RESET: {
    ko: '초기화',
    en: 'Reset',
  },
  EDIT: {
    ko: '수정',
    en: 'Edit',
  },
  ORDER: {
    ko: '순서',
    en: 'Order',
  },
  DAYS: {
    ko: '일',
    en: 'days',
  },
  QUANTITY: {
    ko: '수량',
    en: 'Quantity',
  },
  HISTORY: {
    ko: '내역',
    en: 'History',
  },
  SUBTOTAL: {
    ko: '총액',
    en: 'Subtotal',
  },
  NO_RESULT: {
    ko: '결과가 없습니다.',
    en: 'No Result',
  },
  SALES_TYPE: {
    ko: '주문방식',
    en: 'Sales Type',
  },
  SALES_TYPE_STANDARD_ORDER: {
    ko: '스탠다드 컬러',
    en: 'Standard colors',
  },
  SALES_TYPE_PRINT: {
    ko: '프린트',
    en: 'Print',
  },
  SALES_TYPE_CUSTOM_DYE: {
    ko: '랩딥(B/T)',
    en: 'Custom dye',
  },
  SALES_TYPE_MATCHING_RIB: {
    ko: '매칭립',
    en: 'Matching rib',
  },
  SALES_TYPE_STOCK_ORDER: {
    ko: '스탠다드',
    en: 'Standard',
  },
  SALES_TYPE_CUSTOM_ORDER: {
    ko: '랩딥(B/T)',
    en: 'Custom dye',
  },
  SALES_TYPE_RETAIL: {
    ko: '소매',
    en: 'Retail',
  },
  AVAILABLE: {
    ko: '가능',
    en: 'Available',
  },
  UNAVAILABLE: {
    ko: '불가능',
    en: 'Unavailable',
  },
  APPLY_SAMPLE_RANGE_YES: {
    ko: '샘플과 동일',
    en: 'Same as sample',
  },
  APPLY_SAMPLE_RANGE_NO: {
    ko: '직접 입력',
    en: 'Manual range',
  },
  PRODUCTION_MOQ: {
    ko: '생산최소수량',
    en: 'Production MOQ',
  },
  PRODUCTION_LEAD_TIME: {
    ko: '주문생산납기',
    en: 'Production lead time',
  },
  CUSTOMER_CODE: {
    ko: '바이어',
    en: 'Buyer',
  },
  PURCHASE_CODE: {
    ko: '주문번호',
    en: 'Order number',
  },
  PURCHASE_PRICE: {
    ko: '주문금액',
    en: 'Order value',
  },
  PURCHASE_STATUS: {
    ko: '상태',
    en: 'Status',
  },
  REQUESTED_AT: {
    ko: '요청일자',
    en: 'Requested at',
  },
  QUALITIES: {
    ko: '상품',
    en: 'Items',
  },
  QUALITIES_NEW: {
    ko: '신규상품등록',
    en: 'Register new item',
  },
  GO_TO_QUALITIES: {
    ko: '상품목록으로 이동',
    en: 'Go to items',
  },
  BULKS: {
    ko: '견적',
    en: 'Bulk Quotes',
  },
  PURCHASE: {
    ko: '주문',
    en: 'Order',
  },
  PURCHASES: {
    ko: '주문',
    en: 'Orders',
  },
  PURCHASE_PRODUCTS: {
    ko: '주문상품',
    en: 'Order Products',
  },
  PURCHASE_CONFIRM_SUCCESS: {
    ko: '%s개 주문상품이 출고완료 처리되었습니다.',
    en: '%s orders have been confirmed.',
  },
  PURCHASE_CONFIRM_SUCCESS_LDIP: {
    ko: '%s개 주문상품의 L/DIP 발송이 완료되었습니다.',
    en: '%s L/DIP orders sent.',
  },
  PURCHASE_CONFIRMED: {
    ko: '주문확정내역',
    en: 'Orders (Confirmed)',
  },
  PURCHASE_NEED_SENT: {
    ko: '출고예정',
    en: 'Delivery Needed',
  },
  CONFIRM_SHIPMENT: {
    ko: '출고완료처리',
    en: 'Confirm Shipment',
  },
  CONFIRM_LDIP: {
    ko: 'L/DIP 발송완료처리',
    en: 'Confirm L/DIP',
  },
  STOCKS: {
    ko: '재고',
    en: 'Stock Inventory',
  },
  STOCK_CHECK_REQUESTS: {
    ko: '재고확인요청',
    en: 'Stock Check Requests',
  },
  SETTLEMENTS: {
    ko: '정산',
    en: 'Payments',
  },
  SETTLEMENT_ITEMS: {
    ko: '정산대상목록',
    en: 'Settlement Items',
  },
  VAT_RECEIPT_UPLOAD: {
    ko: '세금계산서 등록',
    en: 'Upload Tax Invoice',
  },
  MENU_SETTINGS: {
    ko: '설정',
    en: 'Settings',
  },
  MENU_CONTACT_US: {
    ko: '고객센터',
    en: 'Help Center',
  },
  MENU_DASHBOARDS: {
    ko: '판매통계',
    en: 'Dashboards',
  },
  MENU_CONTACT_US_FAQ: {
    ko: '자주 묻는 질문',
    en: 'FAQ',
  },
  MENU_CONTACT_US_NOTICE: {
    ko: '공지사항',
    en: 'Notice',
  },
  MENU_CONTACT_US_ABOUT: {
    ko: '스와치온 사업자정보',
    en: 'About SwatchOn',
  },
  IMAGE: {
    ko: '이미지',
    en: 'Image',
  },
  VIDEO: {
    ko: '영상',
    en: 'Video',
  },
  COLOR_NUMBER: {
    ko: '컬러넘버',
    en: 'Color number',
  },
  COLOR_NUMBER_DESC: {
    ko: '컬러넘버는 상품의 컬러별 구분번호입니다. <span class="c-danger">컬러넘버는 등록 후에는 변경이 불가능</span>하며 주문시 사용되는 구분번호입니다.',
    en: 'Color number is an identification number for each color products of the item. <span class="c-danger">The color number cannot be changed once after registered</span> as it is an identification number used for placing orders.',
  },
  NUM_COLOR: {
    ko: '컬러개수',
    en: 'No. of colors',
  },
  NUM_COLOR_TITLE: {
    en: 'How many colors are there for this item?',
    ko: '상품의 컬러는 몇 개인가요?',
  },
  COLOR: {
    ko: '컬러',
    en: 'Color',
  },
  STOCK: {
    ko: '재고수량',
    en: 'Stock qty.',
  },
  SAMPLE: {
    ko: '샘플',
    en: 'Sample',
  },
  WHOLESALE: {
    ko: '도매가',
    en: 'Wholesale',
  },
  UNIT_PRICE: {
    ko: '단가',
    en: 'Price per yard',
  },
  AFTER_LDIP: {
    ko: '랩딥 컨펌 후',
    en: 'from L/DIP',
  },
  LEAD_TIME: {
    ko: '예상납기',
    en: 'Lead time',
  },
  LEAD_TIME_AFTER_LDIP: {
    ko: 'L/DIP 후 예상납기',
    en: 'Leadtime after L/DIP',
  },
  LEAD_TIME_DAYS: {
    ko: '납기 (일)',
    en: 'Lead time (days)',
  },
  QUALITY_LEAD_TIME: {
    ko: '출고납기',
    en: 'Lead time',
  },
  STATUS: {
    ko: '상태',
    en: 'Status',
  },
  REMARK: {
    ko: '비고',
    en: 'Remark',
  },
  PRINT_ALL: {
    ko: '전체 출력하기',
    en: 'Print All',
  },
  DELIVERY_NOTE: {
    ko: '출고장',
    en: 'Delivery Note',
  },
  DATE_OF_ISSUE: {
    ko: '작성일자',
    en: 'Date of Issue',
  },
  SENDER: {
    ko: '발신자',
    en: 'Sender',
  },
  RECEIVER: {
    ko: '수신자',
    en: 'Receiver',
  },
}

const aggregated = {}
const pages = [
  modals,
  errors,
  settings,
  auth,
  quality,
  bulk,
  purchase,
  stockCheckRequest,
  settlement,
  vatReceipt,
  contactUs,
  dashboards,
  common,
]
pages.forEach((page) => Object.assign(aggregated, page))

export default aggregated
