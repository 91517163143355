import client from '@/services'
import {
  StatisticsBestSellingCountryListResponse,
  StatisticsBestSellingQualityListResponse,
  StatisticsSwatchOnBestSellingCategoryListResponse,
  StatisticsSwatchOnBestSellingQualitListResponse,
} from '@/types/services/statistics'

const statisticsService = {
  range: () => client.get('statistics/range'),
  storeBestSellingCountries: (params?: {
    rangeType?: string
    range?: string
    page?: number
  }): Promise<StatisticsBestSellingCountryListResponse> =>
    client.get('statistics/store_best_selling_countries', { params }),
  storeBestSellingQualities: (params?: {
    rangeType?: string
    range?: string
    page?: number
    coutryCodes?: string | string[]
    sorts?: string | string[]
  }): Promise<StatisticsBestSellingQualityListResponse> =>
    client.get('statistics/store_best_selling_qualities', { params }),
  swatchonBestSettlingQualities: (params?: {
    rangeType?: string
    range?: string
    page?: number
    categoryIds?: string | string[]
  }): Promise<StatisticsSwatchOnBestSellingQualitListResponse> =>
    client.get('statistics/swatchon_best_selling_qualities', { params }),
  swatchonBestSettlingCategories: (params?: {
    rangeType?: string
    range?: string
    page?: number
  }): Promise<StatisticsSwatchOnBestSellingCategoryListResponse> =>
    client.get('statistics/swatchon_best_selling_categories', { params }),
}

export default statisticsService
