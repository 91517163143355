// 기존에 interceptor에서 error 자체를 throw 하는게 아니라 error.response를 throw해서 Error는 extends 하지 않음
// catch 문에서 사용하는 프로퍼티만 정의함

class CustomError {
  data: {
    code: string | number
    extra: { guide: string }
    message: string
  }

  constructor({ data }: { data: { code: string; extra: { guide: string }; message: string } }) {
    this.data = data
  }
}

export default CustomError
