import { defineStore } from 'pinia'
import { ref } from 'vue'

import useQualityStore from './quality'
import useStatisticsStore from './statistics'
import { DESKTOP_WIDTH, MOBILE_WIDTH } from '@/constants/device'
import helpers from '@/helpers'
import useUserStore from '@/store/user'
import { Dialog, Modal, Toast } from '@/types/app'

const useAppStore = defineStore('app', () => {
  const userStore = useUserStore()

  const statisticsStore = useStatisticsStore()

  const qualityStore = useQualityStore()

  const isMobile = ref(false)
  const isDesktop = ref(false)
  const windowInnerWidth = ref()
  const windowInnerHeight = ref()
  const setIsMobile = () => {
    windowInnerWidth.value = window.innerWidth || 0
    windowInnerHeight.value = window.innerHeight || 0
    isMobile.value = window.innerWidth < MOBILE_WIDTH
    isDesktop.value = window.innerWidth >= DESKTOP_WIDTH
  }

  const showNavigation = ref(false)
  const toggleShowNavigation = () => {
    showNavigation.value = !showNavigation.value
  }

  const loadConfig = async (canSkipLoadAuthToken = false) => {
    setIsMobile()
    try {
      if (!canSkipLoadAuthToken) {
        await userStore.loadAuthToken()
      }
      await userStore.loadMe()
      await qualityStore.loadPrintMethods()
      await statisticsStore.loadRange()
    } catch (e) {}
  }

  const progressHeader = ref<{
    list: { title: string; stage: string }[]
    currentStep: number
  }>(null)
  const setProgressHeader = (newProgressHeader: { list: { title: string; stage: string }[]; currentStep: number }) => {
    progressHeader.value = newProgressHeader
  }

  const modal = ref<Modal | null>(null)
  const setModal = (options: Modal | null) => {
    modal.value = options
  }

  const toast = ref<Toast | null>()
  const setToast = (options: Toast | null) => {
    toast.value = options
  }

  const dialog = ref<Dialog | null>()
  const setDialog = (options: Dialog | null) => {
    dialog.value = options
  }

  const settings = ref({
    locale: 'ko',
  })
  const setSettings = (newSettings: unknown) => {
    Object.assign(settings.value, { locale: 'ko' }) // locale은 'ko'로 고정
    Object.assign(settings.value, newSettings)
    helpers.localStorage.setMeta('settings', settings.value)
  }

  const uploadProgress = ref<number>(null)
  const uploadProgressObj = ref<{ [key: number]: number }>({})
  const setUploadProgressV2 = (progress: number, id?: number) => {
    if (id) {
      uploadProgressObj.value[id] = progress
      return
    }

    uploadProgress.value = progress
  }

  const isLoading = ref(false)
  const setIsLoading = (loading: boolean) => {
    isLoading.value = loading
  }

  const shouldReFetch = ref(false)
  const setShouldReFetch = (value: boolean) => {
    shouldReFetch.value = value
  }

  const lazyLoadScriptUrls = ref<string[]>([])
  const addLazyLoadScriptUrl = (url: string) => {
    lazyLoadScriptUrls.value.push(url)
  }

  const shouldSkipErrorToast = ref(false)
  const setShouldSkipErrorToast = (value: boolean) => {
    shouldSkipErrorToast.value = value
  }

  const canSkipLeaveModal = ref(false)
  const setCanSkipLeaveModal = (value: boolean) => {
    canSkipLeaveModal.value = value
  }

  return {
    isMobile,
    isDesktop,
    windowInnerWidth,
    windowInnerHeight,
    setIsMobile,
    showNavigation,
    toggleShowNavigation,
    loadConfig,
    progressHeader,
    setProgressHeader,
    modal,
    setModal,
    toast,
    setToast,
    dialog,
    setDialog,
    uploadProgress,
    uploadProgressObj,
    setUploadProgressV2,
    isLoading,
    setIsLoading,
    shouldReFetch,
    setShouldReFetch,
    lazyLoadScriptUrls,
    addLazyLoadScriptUrl,
    shouldSkipErrorToast,
    setShouldSkipErrorToast,
    canSkipLeaveModal,
    setCanSkipLeaveModal,
    settings,
    setSettings,
  }
})

export default useAppStore
