import translate from '@/helpers/translate'

const ERROR_MESSAGE = {
  required: translate('ERROR_REQUIRED'),
  negativeSurplus: translate('ERROR_NEGATIVE_SURPLUS'),
  apiError: translate('ERROR_API'),
  loadQuality: translate('ERROR_LOAD_QUALITY'),
  overZero: translate('ERROR_OVER_ZERO'),
  equalOrOverZero: translate('ERROR_EQUAL_OR_OVER_ZERO'),
  invalidRange: translate('ERROR_INVALID_RANGE'),
  sampleMustBeLessThan20: translate('ERROR_SAMPLE_MUST_BE_LESS_THAN_20'),
  bulkGteqMustBeLessThanLteq: translate('ERROR_BULK_GTEQ_MUST_BE_LESS_THAN_LTEQ'),
  productionMoqTooBig: translate('ERROR_PRODUCTION_MOQ_TOO_BIG'),
  productionLeadTimeTooBig: translate('ERROR_PRODUCTION_LEAD_TIME_TOO_BIG'),
  discountOptionTooBig: translate('ERROR_SAME_DISCOUNT_OPTION'),
  discountOptionTooSmall: translate('ERROR_SMALL_DISCOUNT_OPTION'),
}

export default ERROR_MESSAGE
