import useAppStore from '@/store/app'
import useUserStore from '@/store/user'

export const STORE_NAVIGATION = [
  {
    title: 'QUALITIES',
    group: 'qualities',
    path: '/qualities?page=1',
    icon: 'fal fa-box',
    child: [
      { title: 'QUALITIES', path: '/qualities?page=1' },
      {
        title: 'QUALITIES_NEW',
        path: '/qualities/add-quality?stage=basic',
      },
    ],
  },
  {
    title: 'BULKS',
    group: 'bulks',
    path: '/bulks?page=1',
    icon: 'fal fa-hand-holding-usd',
    child: [{ title: 'BULKS', path: '/bulks?page=1', badge: true }],
  },
  {
    title: 'PURCHASES',
    group: 'purchases',
    path: '/purchases?page=1',
    icon: 'fal fa-clipboard-list',
    child: [
      { title: 'PURCHASES', path: '/purchases?page=1', badge: true },
      { title: 'PURCHASE_CONFIRMED', path: '/purchases/products/confirmed?page=1&sorts=confirmed_at desc' },
      { title: 'PURCHASE_LIST_LDIP_NEED', path: '/purchases/products/need-ldip-sent?page=1' },
      { title: 'PURCHASE_NEED_SENT', path: '/purchases/products/need-sent?page=1' },
    ],
  },
  {
    title: 'STOCKS',
    group: 'stock-check-requests',
    path: '/stock-check-requests?page=1',
    icon: 'fal fa-boxes',
    child: [{ title: 'STOCK_CHECK_REQUESTS', path: '/stock-check-requests?page=1', badge: true }],
  },
  {
    title: 'SETTLEMENTS',
    group: 'settlements',
    path: '/settlements/purchase-products?page=1',
    icon: 'fal fa-won-sign',
    child: [
      { title: 'SETTLEMENT_ITEMS', path: '/settlements/purchase-products?page=1' },
      { title: 'SETTLEMENT_DEPOSITS', path: '/settlements?page=1' },
      {
        title: 'VAT_RECEIPT',
        path: '/settlements/vat-receipts?page=1',
        shouldHide: () => useUserStore().me?.settlementCountry?.code !== 'KR',
      },
      {
        title: 'VAT_RECEIPT_UPLOAD',
        path: '/settlements/vat-receipts/upload-list',
        shouldHide: () => useUserStore().me?.settlementCountry?.code !== 'KR',
      },
    ],
  },
  {
    title: 'MENU_SETTINGS',
    group: 'settings',
    path: '/settings',
    icon: 'far fa-cog',
  },
  {
    title: 'MENU_CONTACT_US',
    group: 'business-information',
    path: '',
    icon: 'fal fa-headset',
    child: [
      {
        title: 'MENU_CONTACT_US_FAQ',
        link: 'https://swatchon.notion.site/14bbd65274da4cb0987f863d620400ab?v=549c08c16a344dcfa226fdb7b630888f&pvs=4',
        path: '',
        shouldHide: () => useAppStore().settings.locale !== 'ko',
      },
      {
        title: 'MENU_CONTACT_US_NOTICE',
        link: 'https://swatchon.notion.site/14bbd65274da4cb0987f863d620400ab?v=549c08c16a344dcfa226fdb7b630888f&pvs=4',
        path: '',
        shouldHide: () => useAppStore().settings.locale !== 'ko',
      },
      {
        title: 'MENU_CONTACT_US_ABOUT',
        group: 'business-information',
        path: '/business-information',
      },
    ],
  },
  {
    title: 'MENU_DASHBOARDS',
    group: 'dashboards',
    path: '',
    icon: 'fal fa-chart-line',
    child: [
      {
        title: 'STORE_BEST_SELLING_COUNTRIES',
        path: '/dashboards/store-best-selling-countries',
      },
      {
        title: 'STORE_BEST_SELLING_QUALITIES',
        path: '/dashboards/store-best-selling-qualities',
      },
      {
        title: 'SWATCHON_BEST_SELLING_CATEGORIES',
        path: '/dashboards/swatchon-best-selling-categories',
      },
    ],
  },
]

export default STORE_NAVIGATION
