import { DataTableField } from '@/types/models/common'

const dataTable = {
  bindingProperty: (field: DataTableField, item: unknown, property: 'class' | 'style' | 'click', event?: Event) => {
    if (field[property]) {
      if (property === 'click') {
        return field[property](item, event)
      }

      return field[property](item)
    }
  },
  getValue: (item: unknown, field: DataTableField): unknown => {
    if (!item || !field.column) {
      return ''
    }

    const cols = field.column.split('.')
    if (cols.length === 1) {
      // Use type assertion to inform TypeScript about the type
      return (item as Record<string, unknown>)[cols[0]] || '-'
    }

    const newField = JSON.parse(JSON.stringify(field))
    newField.column = cols.slice(1).join('.')

    // Use a type guard to narrow down the type
    if (typeof item === 'object' && item !== null && cols[0] in item) {
      return dataTable.getValue((item as Record<string, unknown>)[cols[0]], newField) || '-'
    }

    return '-'
  },
}

export default dataTable
