import json from '@/assets/translations'
// import localStorage from '@/helpers/local-storage'

export const supportedLocales = [
  {
    symbol: 'ko',
    country: 'kr',
  },
  {
    symbol: 'en',
    country: 'us',
  },
]

let globalLocale = 'ko' // (localStorage.getMeta('settings') || {}).locale || 'ko'

if (!supportedLocales.map((o) => o.symbol).includes(globalLocale)) globalLocale = 'ko'

const $t = (key: string, ...args: Array<unknown>) => {
  if (typeof key !== 'string') return

  const upper = (key || '').toUpperCase()
  let result = (json[upper] || {})[globalLocale] || key
  args.forEach((arg: unknown) => (result = result.replace(`%s`, arg as string)))
  return result
}

export default $t
