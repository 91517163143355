import VueBarcode from '@chenfengyuan/vue-barcode'
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import vSelect from 'vue-select'

import App from './App.vue'
import router from './router'
import AppButton from '@/components/app/AppButton.vue'
import AppCheckbox from '@/components/app/AppCheckbox.vue'
import AppToggler from '@/components/app/AppToggler.vue'
import AppDatePickerVue from '@/components/app/input/AppDatePicker.vue'
import AppInput from '@/components/app/input/AppInput.vue'
import AppRadioGroup from '@/components/app/input/AppRadioGroup.vue'
import SearchCard from '@/components/common/SearchCard.vue'
import StockCheckProduct from '@/components/common/StockCheckProduct.vue'
import DataTable from '@/components/data-table/DataTable.vue'
import ModalHeader from '@/components/modals/ModalHeader.vue'
import sentry from '@/helpers/sentry'
import 'vue-select/dist/vue-select.css'
import '@/assets/styles/index.scss'
import '@vuepic/vue-datepicker/dist/main.css'

const pinia = createPinia()

const app = createApp(App)

sentry.useSentry(app)

app
  .use(pinia)
  .use(router)
  .component('VSelect', vSelect)
  .component(VueBarcode.name, VueBarcode)
  .component('AppDatePicker', AppDatePickerVue)
  .component('AppButton', AppButton)
  .component('AppCheckbox', AppCheckbox)
  .component('AppRadioGroup', AppRadioGroup)
  .component('AppInput', AppInput)
  .component('AppToggler', AppToggler)
  .component('DataTable', DataTable)
  .component('ModalHeader', ModalHeader)
  .component('StockCheckProduct', StockCheckProduct)
  .component('SearchCard', SearchCard)
  .mount('#app')
