import authCheck from '@/router/auth-check'

const dashboards = [
  {
    path: '/dashboards/store-best-selling-countries',
    component: () =>
      import(
        /* webpackChunkName: 'view-dashboards' */ '@/components/views/dashboards/ViewStoreBestSellingCountries.vue'
      ),
  },
  {
    path: '/dashboards/store-best-selling-qualities',
    component: () =>
      import(
        /* webpackChunkName: 'view-dashboards' */ '@/components/views/dashboards/ViewStoreBestSellingQualities.vue'
      ),
  },
  {
    path: '/dashboards/swatchon-best-selling-qualities',
    component: () =>
      import(
        /* webpackChunkName: 'view-dashboards' */ '@/components/views/dashboards/ViewSwatchonBestSellingQualities.vue'
      ),
  },
  {
    path: '/dashboards/swatchon-best-selling-categories',
    component: () =>
      import(
        /* webpackChunkName: 'view-dashboards' */ '@/components/views/dashboards/ViewSwatchonBestSellingCategories.vue'
      ),
  },
]

const routes = [
  {
    path: '/',
    redirect: '/qualities?page=1',
  },
  {
    path: '/set-password',
    component: () => import(/* webpackChunkName: 'view-set-password' */ '@/components/views/login/ViewSetPassword.vue'),
    name: 'ViewSetPassword',
  },
  {
    path: '/set-partner-info',
    component: () =>
      import(/* webpackChunkName: 'view-set-partner-info' */ '@/components/views/login/ViewSetPartnerInfo.vue'),
    name: 'ViewSetPartnerInfo',
  },
  {
    path: '/qualities',
    component: () => import(/* webpackChunkName: 'view-qualities' */ '@/components/views/quality/ViewQualities.vue'),
    name: 'ViewQualities',
  },
  {
    path: '/qualities/:id',
    component: () => import(/* webpackChunkName: 'view-quality' */ '@/components/views/quality/ViewQuality.vue'),
    name: 'ViewQuality',
  },
  {
    path: '/qualities/add-quality',
    alias: ['/qualities/edit-quality'],
    component: () =>
      import(/* webpackChunkName: 'view-add-quality-layout' */ '@/components/views/quality/ViewAddQualityLayout.vue'),
    name: 'ViewAddQualityLayout',
  },
  {
    path: '/bulks',
    component: () => import(/* webpackChunkName: 'view-bulks' */ '@/components/views/bulk/ViewBulks.vue'),
    name: 'ViewBulks',
  },
  {
    path: '/bulks/:code',
    component: () => import(/* webpackChunkName: 'view-bulk' */ '@/components/views/bulk/ViewBulk.vue'),
    name: 'ViewBulk',
  },
  {
    path: '/edit-ad-quality/:id',
    component: () =>
      import(/* webpackChunkName: 'view-edit-ad-quality' */ '@/components/views/quality/ViewEditAdQuality.vue'),
    name: 'ViewEditAdQuality',
  },
  {
    path: '/purchases',
    component: () => import(/* webpackChunkName: 'view-purchases' */ '@/components/views/purchase/ViewPurchases.vue'),
    name: 'ViewPurchases',
  },
  {
    path: '/purchases/:id',
    component: () => import(/* webpackChunkName: 'view-purchase' */ '@/components/views/purchase/ViewPurchase.vue'),
    name: 'ViewPurchase',
  },
  {
    path: '/purchases/confirmation/:id',
    component: () =>
      import(
        /* webpackChunkName: 'view-purchase-confirmation' */ '@/components/views/purchase/ViewPurchaseConfirmation.vue'
      ),
    name: 'ViewPurchaseConfirmation',
  },
  {
    path: '/purchases/release',
    component: () =>
      import(/* webpackChunkName: 'view-purchase-release' */ '@/components/views/purchase/ViewPurchaseRelease.vue'),
    name: 'ViewPurchaseRelease',
  },
  {
    path: '/purchases/ldip',
    component: () =>
      import(/* webpackChunkName: 'view-purchase-ldip' */ '@/components/views/purchase/ViewPurchaseLdip.vue'),
    name: 'ViewPurchaseLdip',
  },
  {
    path: '/purchases/products/confirmed',
    component: () =>
      import(
        /* webpackChunkName: 'view-purchase-products-confirmed' */ '@/components/views/purchase/ViewPurchaseProductsConfirmed.vue'
      ),
    name: 'ViewPurchaseProductsConfirmed',
  },
  {
    path: '/purchases/products/need-ldip-sent',
    component: () =>
      import(
        /* webpackChunkName: 'view-purchase-ldip-products' */ '@/components/views/purchase/ViewPurchaseLdipProducts.vue'
      ),
    name: 'ViewPurchaseLdipProducts',
  },
  {
    path: '/purchases/products/need-sent',
    component: () =>
      import(/* webpackChunkName: 'view-purchase-products' */ '@/components/views/purchase/ViewPurchaseProducts.vue'),
    name: 'ViewPurchaseProducts',
  },
  {
    path: '/stock-check-requests',
    component: () =>
      import(/* webpackChunkName: 'view-stocks' */ '@/components/views/stock-check-request/ViewStockCheckRequests.vue'),
  },
  {
    path: '/stock-check-requests/:id',
    component: () =>
      import(/* webpackChunkName: 'view-stocks' */ '@/components/views/stock-check-request/ViewStockCheckRequest.vue'),
  },
  {
    path: '/settlements/purchase-products',
    component: () =>
      import(
        /* webpackChunkName: 'view-settlement-purchase-products' */ '@/components/views/settlement/ViewSettlementsPurchaseProducts.vue'
      ),
    name: 'ViewSettlementsPurchaseProducts',
  },
  {
    path: '/settlements',
    component: () =>
      import(/* webpackChunkName: 'view-settlements' */ '@/components/views/settlement/ViewSettlements.vue'),
    name: 'ViewSettlements',
  },
  {
    path: '/settlements/vat-receipts',
    component: () =>
      import(/* webpackChunkName: 'view-vat-receipts' */ '@/components/views/settlement/ViewVatReceipts.vue'),
    name: 'ViewVatReceipts',
  },
  {
    path: '/settlements/vat-receipts/:id',
    component: () =>
      import(/* webpackChunkName: 'view-vat-receipt' */ '@/components/views/settlement/ViewVatReceipt.vue'),
    name: 'ViewVatReceipt',
  },
  {
    path: '/settlements/vat-receipts/upload-list',
    component: () =>
      import(
        /* webpackChunkName: 'view-vat-receipt-upload-list' */ '@/components/views/settlement/ViewVatReceiptUploadList.vue'
      ),
    name: 'ViewVatReceiptUploadList',
  },
  {
    path: '/settlements/:id',
    component: () =>
      import(/* webpackChunkName: 'view-settlement' */ '@/components/views/settlement/ViewSettlement.vue'),
    name: 'ViewSettlement',
  },
  {
    path: '/business-information',
    component: () =>
      import(/* webpackChunkName: 'view-business-information' */ '@/components/views/ViewBusinessInformation.vue'),
    name: 'ViewBusinessInformation',
  },
  {
    path: '/settings',
    component: () => import(/* webpackChunkName: 'view-settings' */ '@/components/views/ViewSettings.vue'),
    name: 'ViewSettings',
  },
  {
    path: '/settings/edit',
    component: () => import(/* webpackChunkName: 'view-settings-edit' */ '@/components/views/ViewSettingsEdit.vue'),
    name: 'ViewSettingsEdit',
  },
  ...dashboards,
].map((route) => ({
  ...route,
  beforeEnter: authCheck,
}))

export default routes
