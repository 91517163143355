import { defineStore } from 'pinia'
import { ref } from 'vue'

import qualityService from '@/services/quality'
import {
  ProductOfQuality,
  Quality,
  QualityBasicRequest,
  QualityPriceTagRequest,
  QualityPropertyListResponse,
} from '@/types/services/quality'

const assignChildrenPriceTagCurrency = (quality: Quality) => {
  if (quality.standardOrderPriceTagGroup) {
    quality.standardOrderPriceTagGroup.qualityPriceTags.forEach((tag) => {
      const assignable = tag
      assignable.$$currency = quality.standardOrderPriceTagGroup.currency
    })
  }
  if (quality.customDyePriceTagGroup) {
    quality.customDyePriceTagGroup.qualityPriceTags.forEach((tag) => {
      const assignable = tag
      assignable.$$currency = quality.customDyePriceTagGroup.currency
    })
  }
  if (quality.printPriceTagGroup) {
    quality.printPriceTagGroup.qualityPriceTags.forEach((tag) => {
      const assignable = tag
      assignable.$$currency = quality.printPriceTagGroup.currency
    })
  }
  if (quality.retailPriceTagGroup) {
    quality.retailPriceTagGroup.qualityPriceTags.forEach((tag) => {
      const assignable = tag
      assignable.$$currency = quality.retailPriceTagGroup.currency
    })
  }
}

const useQualityStore = defineStore('quality', () => {
  const qualityProperties = ref<QualityPropertyListResponse>()

  const quality = ref<Quality>()

  const products = ref<ProductOfQuality[]>()

  const printMethods = ref(null)

  const loadQualityProperties = async () => {
    try {
      qualityProperties.value = await qualityService.getQualityProperties()
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const postQualityBasicInfo = async (payload: QualityBasicRequest) => {
    const data = await qualityService.postQualityBasicInfo(payload)
    return data
  }

  const loadQuality = async (id: number) => {
    quality.value = await qualityService.getQuality(id)
    assignChildrenPriceTagCurrency(quality.value)
    return quality.value
  }

  const loadProducts = async (id: number) => {
    products.value = (await qualityService.getProducts(id)).products
    return products.value
  }

  const loadPrintMethods = async () => {
    printMethods.value = await qualityService.getPrintingMethods()
    return printMethods.value
  }

  const updatePriceTags = (id: number, payload: QualityPriceTagRequest) =>
    qualityService.putQualityPriceTags(payload, id)

  const updatePurchaseInfo = async (id: number, payload: { productionMoq: number; productionLeadTime: number }) => {
    try {
      const result = await qualityService.putQualityPurchaseInfo(
        {
          productionMoq: payload.productionMoq,
          productionLeadTime: payload.productionLeadTime,
        },
        id,
      )
      if (quality.value) quality.value = result
      return result
    } catch (e) {
      return Promise.reject(e)
    }
  }

  return {
    quality,
    qualityProperties,
    products,
    printMethods,
    loadPrintMethods,
    loadQualityProperties,
    postQualityBasicInfo,
    loadQuality,
    loadProducts,
    updatePriceTags,
    updatePurchaseInfo,
  }
})

export default useQualityStore
