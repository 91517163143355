import { defineStore } from 'pinia'
import { ref } from 'vue'

import stockService from '@/services/stock'
import { StockCheckProduct, StockCheckRequest } from '@/types/services/stock-check-request'

const useStockStore = defineStore('stock', () => {
  const stockCheckRequest = ref<StockCheckRequest>()

  const loadStockCheckRequest = async (id: number) => {
    stockCheckRequest.value = (await stockService.detail(id)) as StockCheckRequest
    const arr = stockCheckRequest.value.stockCheckProducts
    arr.forEach((scp, idx) => {
      const o = arr[idx] as unknown as { productId: number } // TS sucks
      o.productId = scp.product.id
    })
    return stockCheckRequest.value
  }

  const confirmStockCheckRequest = async (id: number, stockCheckProducts: StockCheckProduct[]) => {
    await stockService.create(id, stockCheckProducts)
  }

  return { stockCheckRequest, loadStockCheckRequest, confirmStockCheckRequest }
})

export default useStockStore
