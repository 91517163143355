import client from '@/services'
import {
  Purchase,
  PurchaseConfirmRequest,
  PurchaseListResponse,
  PurchaseProduct,
  PurchaseProductListConfirmedResponse,
  PurchaseProductsSentRequest,
} from '@/types/services/purchase'

const purchaseService = {
  getPurchase: (id: number): Promise<Purchase> => client.get(`purchases/${id}`),
  getPurchases: (params?: {
    code?: string | string[]
    customerCode?: string | string[]
    qualityCode?: string | string[]
    createdAtGteq?: string | string[]
    createdAtLteq?: string | string[]
    page?: number
  }): Promise<PurchaseListResponse> => client.get('purchases', { params }),
  getPurchaseProductsConfirmed: (params?: { page?: number }): Promise<PurchaseProductListConfirmedResponse> =>
    client.get('purchase_products/confirmed', { params }),
  getPurchaseProductsLdip: (params?: {
    purchaseCode?: string | string[]
    productQualityName?: string | string[]
    page?: number
    sorts?: string[]
  }): Promise<PurchaseProductListConfirmedResponse> => client.get('purchase_products/need_ldip_sent', { params }),
  getPurchaseProducts: (params?: {
    purchaseCode?: string | string[]
    productQualityName?: string | string[]
    page?: number
    sorts?: string[]
  }): Promise<PurchaseProductListConfirmedResponse> => client.get('purchase_products/need_sent', { params }),
  getConfigs: (): Promise<{ key: string; value: string }[]> => client.get('configs'),
  putPurchasesConfirm: (payload: { purchaseProducts: PurchaseConfirmRequest[] }, id: number): Promise<Purchase> =>
    client.put(`purchases/${id}/confirm`, payload),
  putPurchasesProductsPickupAt: (payload: {
    purchaseProducts: { id: number; pickupAt: string; deliveryMethod: 'quick' | 'logistics' }[]
  }): Promise<PurchaseProduct> => client.put(`purchase_products/pickup_at`, payload),

  putPurchasesProductsLdipPickupAt: (payload: {
    purchaseProducts: { id: number; ldipPickupAt: string }[]
  }): Promise<PurchaseProduct> => client.put(`purchase_products/ldip_pickup_at`, payload),

  putPurchaseProductsSent: (payload: { purchaseProducts: PurchaseProductsSentRequest[] }): Promise<PurchaseProduct[]> =>
    client.put(`purchase_products/sent`, payload),

  putPurchaseProductsLdipSent: (payload: {
    purchaseProducts: PurchaseProductsSentRequest[]
  }): Promise<PurchaseProduct[]> => client.put(`purchase_products/ldip_sent`, payload),
}

export default purchaseService
