<template>
  <div class="app-loader">
    <div class="app-loader-container">
      <img class="loader-app-loader-main" :src="require('@/assets/images/small-logo-white.svg')" />
    </div>

    <div class="app-loader-container main">
      <img class="loader-app-loader-main" :src="require('@/assets/images/small-logo-white.svg')" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.app-loader {
  --app-loader-size: 100px;
  width: var(--app-loader-size);
  height: calc(var(--app-loader-size) * 30 / 100);
  position: relative;

  @keyframes width {
    0% {
      width: 0;
    }
    50% {
      width: 50%;
    }
    100% {
      width: 100%;
    }
  }

  .app-loader-container {
    position: absolute;
    height: 100%;

    img {
      width: var(--app-loader-size);
      filter: opacity(0.45) drop-shadow(0 0 0 var(--white));
    }

    &.main {
      animation: width 2s infinite forwards cubic-bezier(0.785, 0.135, 0.15, 0.86);
      overflow: hidden;

      img {
        width: var(--app-loader-size);
        filter: opacity(0.65) drop-shadow(0 0 0 var(--white));
      }
    }
  }
}
</style>
