const cdnUrl = process.env.VUE_APP_CDN_URL

const getWithCdnUrl = (path: string, type = 'images/large') => {
  if (!path) {
    return
  }

  if (path.startsWith('http')) {
    return path
  }

  if (path.startsWith('/')) {
    return `${cdnUrl}${path}`
  }

  const startsWithSlashPath = path.startsWith('/') ? path : `/${path}`

  return `${cdnUrl}/${type}${startsWithSlashPath}`
}

export default getWithCdnUrl
