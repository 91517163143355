import useAppStore from '@/store/app'
import { Modal } from '@/types/app'

const modal = {
  confirm: (option: Modal) => useAppStore().setModal(option),
  custom: (option: Modal) => useAppStore().setModal(option),
  abortAlert: (next: () => void) =>
    useAppStore().setModal({
      component: 'ModalBasic',
      body: 'MODAL_ABORT_EDITING',
      buttons: [
        { text: 'LEAVE', class: 'btn-secondary', onClick: () => next() },
        { text: 'STAY', class: 'btn-primary' },
      ],
    }),
}

export default modal
