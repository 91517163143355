import { defineStore } from 'pinia'
import { ref } from 'vue'

import purchaseService from '@/services/purchase'
import { Purchase, PurchaseConfirmRequest } from '@/types/services/purchase'

const usePurchaseStore = defineStore('purchase', () => {
  const purchase = ref<Purchase>()
  const loadPurchase = async (id: number) => {
    purchase.value = await purchaseService.getPurchase(id)
    return purchase.value
  }

  const confirmRequestFromModal = ref<PurchaseConfirmRequest>()
  const setConfirmRequestFromModal = (request: PurchaseConfirmRequest) => {
    confirmRequestFromModal.value = request
  }

  const prevConfirmPurchaseConfirmRequest = ref<PurchaseConfirmRequest>()
  const setPrevConfirmPurchaseConfirmRequest = (request: PurchaseConfirmRequest) => {
    prevConfirmPurchaseConfirmRequest.value = request
  }

  return {
    purchase,
    loadPurchase,
    confirmRequestFromModal,
    setConfirmRequestFromModal,
    prevConfirmPurchaseConfirmRequest,
    setPrevConfirmPurchaseConfirmRequest,
  }
})

export default usePurchaseStore
