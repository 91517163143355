const modalPurchaseConfirmationCard = {
  ACCEPT_ORDER: {
    ko: '주문수락',
    en: 'Accept Order',
  },
  CANCEL_ORDER: {
    ko: '주문취소',
    en: 'Cancel Order',
  },
  PURCHASE_PRODUCT: {
    ko: '주문상품',
    en: 'Product',
  },
  MAXIMUM: {
    ko: '최대',
    en: 'Maximum',
  },
  LEAD_TIME_INCLUDES_WEEKENDS_AND_HOLIDAYS: {
    ko: '공휴일 포함',
    en: 'including holidays',
  },
  REASON_PRODUCTION_TERMINATED: {
    ko: '생산종료사유',
    en: `Tell us why it's unable to produce new batch.`,
  },
  REASON_OPTION_NOT_AVAILABLE: {
    ko: '립제공불가사유',
    en: 'Tell us why the option is not available.',
  },
  DELIVERABLE_AFTER: {
    ko: '%s 이후 출고 가능',
    en: 'Can only be shipped out after %s',
  },
  OPTION_NOT_AVAILABLE: {
    ko: '립 제공 불가',
    en: 'Option not available',
  },
  OPTION_NOT_AVAILABLE_DESC: {
    ko: '립 상품의 납기지연 / 생산불가등 제공 불가한 상황',
    en: 'Due to delayed ship-out / production',
  },
  MUST_CANCEL_PURCHASE_IF: {
    ko: '%s 이내 출고 불가능한 경우 주문취소로 처리 부탁드립니다.',
    en: 'Please cancel the order if it cannot be shipped within %s.',
  },
  MATCHING_RIB_MUST_BE_SHIPPED_TOGETHER: {
    ko: '매칭립 상품은 세트로 출고되어야 합니다. 바디 상품과 함께 출고할 수 있는 방식과 날짜를 입력해주세요.',
    en: 'Matching rib products must be shipped together. Please enter the method and date that can be shipped together with the body product.',
  },
  WHY_CHECK_STOCK_ON_CONFIRM: {
    ko: '본 주문은 샘플 주문으로, 현재 재고 수량을 알려주시면 고객의 빠른 벌크 주문 전환에 도움이 됩니다.',
    en: 'This order is a sample order, and letting us know the current stock quantity will help the customer convert to bulk order quickly.',
  },
  PURCHASE_CONFIRM_STOCK_30: {
    ko: '50 yds 미만',
    en: 'Less than 50 yds',
  },
  PURCHASE_CONFIRM_STOCK_50: {
    ko: '50 ~ 100 yds',
    en: '50 ~ 100 yds',
  },
  PURCHASE_CONFIRM_STOCK_100: {
    ko: '100 yds 이상',
    en: 'More than 100 yds',
  },
  ERROR_PURCHASE_CONFIRM_TODO_OUT_OF_STOCK: {
    ko: '본 건 주문수량은 <b>%s yds</b>입니다. 현재 재고수량이 <b>%s yds</b>라면 주문수락 부탁드립니다.',
    en: 'The order quantity is <b>%s yds</b>. If the current stock quantity is <b>%s yds</b>, please accept the order.',
  },
  ERROR_PURCHASE_CONFIRM_TODO_DELAYED: {
    ko: '본 건은 주문생산 가능하며, 납기만 출고기한보다 늦는 상황이므로, 납기지연으로 처리 부탁드립니다.',
    en: 'This item can be ordered and delivered, just delivery date is later than the delivery deadline, so please process it as delayed.',
  },
  ERROR_PURCHASE_CONFIRM_TODO_ACCEPTABLE: {
    ko: '본 건은 주문생산 가능하며, 출고기한은 <b>%s</b>로 기한 내 납품 가능하므로, 주문수락 후 생산진행 부탁드립니다.',
    en: 'This item can be ordered and delivered, and the delivery deadline is <b>%s</b>, so please accept the order and proceed with production.',
  },
  ERROR_PURCHASE_CONFIRM_TODO_DELAYED_EXTENDED_LEAD_TIME: {
    ko: '<b>%s일</b> 내에 출고 가능하다면 주문수락 부탁드립니다. <b>%s</b> 이내 출고하면 문제없는 주문 건입니다.',
    en: 'Please accept the order if you can ship within <b>%s days</b>. If you ship within <b>%s</b>, there is no problem with the order.',
  },
  ERROR_PURCHASE_CONFIRM_TODO_PRINT_NOT_AVAILABLE: {
    ko: '본 건 주문수량은 <b>%s yds</b>입니다. 프린트가능수량이 <b>%s yds</b>라면 주문수락 부탁드립니다.',
    en: 'The order quantity is <b>%s yds</b>. If the current stock quantity is <b>%s yds</b>, please accept the order.',
  },
  ERROR_PURCHASE_CONFIRM_TODO_PRODUCTION_NOT_AVAILABLE: {
    ko: '본 건 주문수량은 <b>%s yds</b>입니다. 생산가능수량이 <b>%s yds</b>라면 주문수락 부탁드립니다.',
    en: 'The order quantity is <b>%s yds</b>. If the current stock quantity is <b>%s yds</b>, please accept the order.',
  },
  ERROR_PURCHASE_CONFIRM_TODO_UNIT_PRICE_RISEN: {
    ko: '기존 판매 단가(%s)보다 높은 값을 입력하셔야 합니다.',
    en: 'You must enter a higher price than the current unit price.(%s)',
  },
  ERROR_PURCHASE_CONFIRM_TODO_UNIT_PRICE_RISEN_REQUIRED: {
    ko: '단가 상승이 반영된 현재 판매 단가를 입력해 주세요.',
    en: 'Please enter the current unit price reflecting the price increase.',
  },
  DISCOUNT_OFFER: {
    ko: '이 주문에 한해 할인가 제공이 가능합니까?',
    en: 'Can you offer a discount for this order?',
  },
  DISCOUNT_OFFER_NO: {
    ko: '아니오 (%s)',
    en: 'No',
  },
  DISCOUNT_OFFER_PLACEHOLDER: {
    ko: '할인이 적용된 상품 단가를 입력 해 주세요.',
    en: 'Please enter the discounted price.',
  },
}

export default modalPurchaseConfirmationCard
