import client from '@/services'
import {
  BadgeResponse,
  MeResponse,
  NotificationResponse,
  PartnerInfoRequest,
  SignInResponse,
} from '@/types/services/user'

const userService = {
  me: (): Promise<MeResponse> => client.get('partners/me'),
  signIn: ({ partnerId, password }: { partnerId: string; password: string }): Promise<SignInResponse> =>
    client.post('partners/signin', { partnerId, password }),
  signOut: () => client.put('partners/signout'),
  setPassword: ({
    oldPassword,
    password,
    passwordConfirmation,
  }: {
    oldPassword: string
    password: string
    passwordConfirmation: string
  }): Promise<MeResponse> => client.put('partners/password', { oldPassword, password, passwordConfirmation }),
  getBadge: (): Promise<BadgeResponse> => client.get('/partners/me/badge'),
  getNotification: (): Promise<NotificationResponse> => client.get('partners/me/notification'),
  putParterInfo: (payload: PartnerInfoRequest) => client.put('partners/business_info', payload),
  putNotification: (payload: { phones: string[]; emails: string[] }) => client.put('partners/me/notification', payload),
}

export default userService
