import client from '@/services'
import {
  StockCheckProduct,
  StockCheckProductListResponse,
  StockCheckRequest,
} from '@/types/services/stock-check-request'

const stockService = {
  all: (params?: {
    code?: string | string[]
    customerCode?: string | string[]
    createdAtGteq?: string | string[]
    createdAtLteq?: string | string[]
    status?: string | string[]
    page?: number
  }): Promise<StockCheckProductListResponse> => client.get('stock_check_requests', { params }),
  detail: (id: number): Promise<StockCheckRequest> => client.get(`stock_check_requests/${id}`),
  create: (id: number, stockCheckProducts: StockCheckProduct[]) =>
    client.post(`stock_check_requests/${id}/confirm`, { stockCheckProducts }),
}

export default stockService
