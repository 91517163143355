import { defineStore } from 'pinia'
import { ref } from 'vue'

import statisticsService from '@/services/statistics'

const useStatisticsStore = defineStore('statistics', () => {
  const range = ref(null)

  const loadRange = async () => {
    try {
      range.value = await statisticsService.range()
    } catch (e) {}
  }

  return { range, loadRange }
})

export default useStatisticsStore
