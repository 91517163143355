import helpers from '.'
import { ProductOfQuality } from '@/types/services/quality'

const template = {
  case: {
    toSnake: (str: string, delim?: string) =>
      str.replace(/[A-Z]/g, (letter) => `${delim || '_'}${letter.toLowerCase()}`),
    toCamel: (str: string) =>
      (str || '').replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', '')),
    toCapital: (str: string) => (str || '').charAt(0).toUpperCase() + str.slice(1),
    toPascal: (str: string) => template.case.toCapital(template.case.toCamel(str)),
  },
  colorString: (product: ProductOfQuality) => (product.colors || []).map((c) => c.name).join(', '),
  prettyNumber: (paramNum: number | string, minimumFractionDigits = 2) => {
    if (!helpers.nonEmpty(paramNum)) return null

    const num = parseFloat(paramNum as string).toFixed(minimumFractionDigits)
    return parseFloat(num).toLocaleString(undefined, { minimumFractionDigits })
  },
  currencySign: (currency: string) => {
    if (currency.toLowerCase() === 'krw') return '₩'
    if (currency.toLowerCase() === 'usd') return '$'
  },
  price: (price: number, currency?: string) => {
    let prefix = '$'
    if ((currency || '').toLowerCase() === 'krw') prefix = '₩'
    return `${price < 0 ? '- ' : ''}${prefix} ${template.prettyNumber(
      Math.abs(price),
      currency && currency === 'krw' ? 0 : 2,
    )}`
  },
  withLeadingZero: (value: number, numDigits: number) => {
    const numDigitsOfGivenValue = value.toString().length
    return `${'0'.repeat(numDigits - numDigitsOfGivenValue)}${value}`
  },
  productColorCode: (product: ProductOfQuality) => {
    const createCode = () => {
      if (product?.storeColorCode) return product?.storeColorCode
      if (helpers.nonEmpty(product?.colorNumber)) return product?.colorNumber.toString()
    }
    const code = createCode()
    if (!code || code.includes('pseudo')) return '-'
    return code
  },
  formatDate: (date: Date) => {
    const year = date.getFullYear()
    let month = (date.getMonth() + 1).toString()
    let day = date.getDate().toString()

    month = month.length < 2 ? `0${month}` : month
    day = day.length < 2 ? `0${day}` : day

    return `${year}-${month}-${day}`
  },
  localizedPhone: (phoneNumber: string, locale?: string) => {
    if (locale === 'ko') return phoneNumber

    // remove 0 and replace it to 82. For example, 02-462-1015 => +82-2-462-1015
    return `+82-${phoneNumber.replace(/^0/, '')}`
  },
}

export default template
