import helpers from '@/helpers'
import router from '@/router'
import { DataTableModel } from '@/types/models/common'
import { StockCheckProduct, StockCheckRequest } from '@/types/services/stock-check-request'

export const actionButton = (o: StockCheckProduct) => {
  const btnDone = (clickable?: boolean) => ({
    style: `color: var(--white); background: var(--success); ${clickable ? 'cursor: pointer;' : ''}`,
    text: `<i class="fal fa-check m-r-4"></i>${helpers.$t('CHECK_STOCK')}`,
  })

  if (o.status === 'requested') {
    return {
      style: 'color: var(--success); border: 1px solid var(--success); cursor: pointer;',
      text: `${helpers.$t('CHECK_STOCK')}<i class="fal fa-chevron-right m-l-4"></i>`,
    }
  }

  if (o.status === 'completed' || o.status === 'confirmed') return btnDone()

  if (o.status === 'cancelled')
    return {
      style: 'color: var(--gray-400); border: 1px solid var(--gray-400)',
      text: `${helpers.$t('CHECK_STOCK')}<i class="fal fa-chevron-right m-l-4"></i>`,
    }
}

export const badgesStockStatus = {
  requested: {
    text: helpers.$t('STOCK_CHECK_REQUEST_STATUS_REQUESTED'),
    color: 'warning',
  },
  confirmed: {
    text: helpers.$t('STOCK_CHECK_REQUEST_STATUS_CONFIRMED'),
    color: 'success',
  },
  cancelled: {
    text: helpers.$t('STOCK_CHECK_REQUEST_STATUS_CANCELLED'),
    color: 'gray-400',
  },
  completed: {
    text: helpers.$t('STOCK_CHECK_REQUEST_STATUS_COMPLETED'),
    color: 'success',
  },
}

export const stockCheckProductsModel: DataTableModel = {
  fields: [
    {
      title: 'IMAGE',
      column: 'product.image.small',
      type: 'image',
    },
    {
      title: 'QUALITY_NAME',
      column: 'product.quality.name',
      class: () => ({ 'clickable c-primary': true }),
      click: (stockCheckProduct: StockCheckProduct) => {
        router.push(`/qualities/${stockCheckProduct.product.quality.id}`)
      },
    },
    {
      title: 'COLOR_NUMBER',
      column: 'product.storeColorCode',
      filter: (stockCheckProduct: StockCheckProduct) => helpers.template.productColorCode(stockCheckProduct.product),
    },
    {
      title: 'PRODUCT_CODE',
      column: 'product.code',
    },
    {
      title: 'COLOR',
      filter: (stockCheckProduct: StockCheckProduct) =>
        (stockCheckProduct.product.colors || []).map((c) => c.name).join(', '),
    },
    {
      title: 'REQUESTED_QUANTITY',
      column: 'requestedQuantity',
    },
    {
      title: 'STOCK',
      column: 'stock',
    },
    {
      title: 'REPRODUCTION_AVAILABILITY',
      filter: (stockCheckProduct: StockCheckProduct) => {
        if (
          !helpers.nonEmpty(stockCheckProduct.reproductionAvailability) ||
          stockCheckProduct.reproductionAvailability === 'unspecified'
        ) {
          return '-'
        }

        if (
          stockCheckProduct.reproductionAvailability === 'unavailable' &&
          stockCheckProduct.unreproducibleRange !== 'unbounded'
        ) {
          return `${helpers.$t(
            `REPRODUCTION_AVAILABILITY_${stockCheckProduct.reproductionAvailability}`,
          )} - ${helpers.$t(`UNREPRODUCIBLE_RANGE_${stockCheckProduct.unreproducibleRange}`)}`
        }

        return helpers.$t(`REPRODUCTION_AVAILABILITY_${stockCheckProduct.reproductionAvailability}`)
      },
      class: (stockCheckProduct: StockCheckProduct) => {
        if (
          !helpers.nonEmpty(stockCheckProduct.reproductionAvailability) ||
          stockCheckProduct.reproductionAvailability === 'unspecified'
        )
          return

        return `badge-${stockCheckProduct.reproductionAvailability ? 'success' : 'danger'}`
      },
    },
    {
      title: 'MOQ',
      column: 'reproductionQuantity',
    },
    {
      title: 'REPRODUCTION_DAYS',
      column: 'reproductionDays',
      filter: (stockCheckProduct: StockCheckProduct) => {
        if (!stockCheckProduct.reproductionDays) return '-'

        return stockCheckProduct.reproductionDays
      },
    },
    {
      title: 'CHECK_STOCK',
      filter: (o: StockCheckProduct) => {
        return `<div
          style="border-radius: 4px; padding: 4px 8px; font-size: 12px; ${actionButton(o).style}"
        >${actionButton(o).text}</div>`
      },
      click: (stockCheckProduct: StockCheckProduct) => {
        if (stockCheckProduct.status !== 'requested') return

        helpers.modal.custom({
          component: 'ModalStockCheckProduct',
          data: {
            stockCheckProduct,
          },
        })
      },
    },
  ],
}

export const stockCheckRequestsModel: DataTableModel = {
  fields: [
    {
      title: 'STOCK_CHECK_REQUEST_CODE',
      column: 'code',
      class: (stockCheckRequest: StockCheckRequest) => ({
        'c-primary clickable': true,
        'alarm-badge': stockCheckRequest.status === 'requested',
      }),
      click: (stockCheckRequest: StockCheckRequest) => {
        router.push(`/stock-check-requests/${stockCheckRequest.id}`)
      },
    },
    {
      title: 'CUSTOMER_CODE',
      column: 'customerCode',
    },
    {
      title: 'REQUESTED_AT',
      column: 'createdAt',
      filter: (stockCheckRequest: StockCheckRequest) =>
        helpers.template.formatDate(new Date(stockCheckRequest.createdAt)),
    },
    {
      title: 'QUALITY_NAME',
      filter: (stockCheckRequest: StockCheckRequest) => {
        const firstStockCheckProduct = (stockCheckRequest.stockCheckProducts || [])[0]
        if (!firstStockCheckProduct) return '-'

        return firstStockCheckProduct.product.quality.name
      },
    },
    {
      title: 'NUM_COLOR',
      filter: (stockCheckRequest: StockCheckRequest) => (stockCheckRequest.stockCheckProducts || []).length,
    },
    {
      title: 'STATUS',
      column: 'status',
      filter: (stockCheckRequest: StockCheckRequest) => badgesStockStatus[stockCheckRequest.status].text,
      class: (stockCheckRequest: StockCheckRequest) =>
        `light-badge-${badgesStockStatus[stockCheckRequest.status].color}`,
    },
  ],
}

export default stockCheckRequestsModel
