import dayjs from 'dayjs'

import getWithCdnUrl from './cdn'
import dataTable from './data-table'
import localStorage from './local-storage'
import modal from './modal'
import regex from './regex'
import sentry from './sentry'
import template from './template'
import toast from './toast'
import $t from './translate'
import useAppStore from '@/store/app'

const helpers = {
  withCdn: getWithCdnUrl,
  case: template.case,
  template,
  modal,
  toast,
  dayjs,
  regex,
  reportError: (error: unknown | string) => sentry.Sentry.captureException(error),
  dataTable,
  localStorage,
  $t,
  nonEmpty: (value: unknown) => value !== null && value !== undefined && value !== '',
  countryImg: (code: string) => {
    if (!code) {
      return ''
    }

    return `https://raw.githubusercontent.com/lipis/flag-icon-css/d81077e4e9648ba32546fad3b77932b48feb344b/flags/4x3/${code.toLowerCase()}.svg`
  },
  loadScript: ({ url, attributes }: { url: string; attributes?: { [key: string]: string }[] }) =>
    new Promise((resolve) => {
      if (useAppStore().lazyLoadScriptUrls.includes(url)) {
        resolve(null)
        return
      }

      const script = document.createElement('script')
      if (attributes) {
        attributes.forEach((attr) => script.setAttribute(attr.key, attr.value))
      }

      script.src = url
      script.async = true
      script.onload = resolve
      document.head.appendChild(script)
      useAppStore().addLazyLoadScriptUrl(url)
    }),
}

export default helpers
