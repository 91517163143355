import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useRouter } from 'vue-router'

import { clearRequestHeader, setRequestHeader } from '@/services'
import userService from '@/services/user'
import useAppStore from '@/store/app'
import { BadgeResponse, MeResponse } from '@/types/services/user'

const afterSignIn = async (token: string, isPersistSignIn: boolean) => {
  const appStore = useAppStore()

  try {
    if (isPersistSignIn) {
      window.localStorage.setItem('header', JSON.stringify({ token }))
      await appStore.loadConfig()
      return
    }

    window.localStorage.removeItem('header')
    setRequestHeader({ token })
    await appStore.loadConfig(true)
  } catch (e) {
    return Promise.reject(e)
  }
}

const useUserStore = defineStore('user', () => {
  const router = useRouter()

  const header = ref('')
  const loadAuthToken = async () => {
    const h = window.localStorage.getItem('header')
    if (!h) {
      window.localStorage.removeItem('header')
      return Promise.reject()
    }
    header.value = h
    setRequestHeader(JSON.parse(h))
  }

  const isKoreaCountry = ref(false)
  const me = ref<MeResponse>()
  const loadMe = async () => {
    try {
      me.value = await userService.me()
      isKoreaCountry.value = me.value.country.id === 31
    } catch (e) {}
  }

  const signIn = async ({
    partnerId,
    password,
    isPersistSignIn,
  }: {
    partnerId: string
    password: string
    isPersistSignIn: boolean
  }) => {
    try {
      const { token } = await userService.signIn({ partnerId, password })
      await afterSignIn(token, isPersistSignIn)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  // TODO: 추후 store 전체를 reset해야함
  const resetAppStates = () => {
    me.value = null
    useAppStore().setCanSkipLeaveModal(false)
    window.localStorage.removeItem('header')
    clearRequestHeader()
  }

  const signOut = async () => {
    const appStore = useAppStore()
    try {
      appStore.setIsLoading(true)
      await userService.signOut()
    } catch (e) {
      return Promise.reject(e)
    } finally {
      appStore.setIsLoading(false)
      router.push('/login').then(resetAppStates)
    }
  }

  const tmpOldPassword = ref('')
  const setTmpOldPassword = (oldPassword: string) => {
    tmpOldPassword.value = oldPassword
  }

  const setPassword = async ({
    oldPassword,
    password,
    passwordConfirmation,
  }: {
    oldPassword: string
    password: string
    passwordConfirmation: string
  }) => {
    try {
      await userService.setPassword({ oldPassword, password, passwordConfirmation })
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const alarmBadge = ref<BadgeResponse>({ bulks: 0, purchases: 0, stockCheckRequests: 0 })
  const loadAlarmBadge = async () => {
    try {
      alarmBadge.value = await userService.getBadge()
    } catch (e) {
      return Promise.reject(e)
    }
  }

  return {
    loadAuthToken,
    me,
    loadMe,
    signIn,
    signOut,
    tmpOldPassword,
    setTmpOldPassword,
    setPassword,
    isKoreaCountry,
    alarmBadge,
    loadAlarmBadge,
  }
})

export default useUserStore
