const routes = [
  {
    path: '/login',
    component: () => import(/* webpackChunkName: 'view-login' */ '@/components/views/login/ViewLogin.vue'),
    name: 'ViewLogin',
  },
  {
    path: '/backdoor',
    component: () => import(/* webpackChunkName: 'view-backdoor' */ '@/components/views/ViewBackdoor.vue'),
    name: 'ViewBackdoor',
  },
]

export default routes
