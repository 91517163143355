import { defineStore } from 'pinia'
import { ref } from 'vue'

import settlementService from '@/services/settlement'
import { Settlement, SettlementItem, VatReceipt } from '@/types/services/settlement'

export const populateRemarkIndex = (settlementItems: Array<SettlementItem>) => {
  let remarkIndex = 1
  const arr = settlementItems
  arr.forEach((item, idx) => {
    if (item.remark) {
      arr[idx].$$remarkIndex = remarkIndex
      remarkIndex += 1
    }
  })
}

const useSettlementStore = defineStore('settlement', () => {
  const settlement = ref<Settlement>()

  const loadSettlement = async (id: number) => {
    settlement.value = await settlementService.getSettlement(id)
    populateRemarkIndex(settlement.value.settlementItems)
    return settlement.value
  }

  const settlementCurrency = ref<'krw' | 'usd'>()
  const setSettlementCurrency = (currency: 'krw' | 'usd') => {
    settlementCurrency.value = currency
  }

  const vatReceipt = ref<VatReceipt>()
  const loadVatReceipt = async (id: number) => {
    vatReceipt.value = await settlementService.getVatReceipt(id)
    return vatReceipt.value
  }
  return {
    settlement,
    loadSettlement,
    settlementCurrency,
    setSettlementCurrency,
    vatReceipt,
    loadVatReceipt,
  }
})

export default useSettlementStore
