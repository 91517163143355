import axios from 'axios'

import ERROR_MESSAGE from '@/constants/error-message'
import CustomError from '@/error'
import helpers from '@/helpers'
import router from '@/router'
import useAppStore from '@/store/app'
import useUserStore from '@/store/user'

const client = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
})

client.interceptors.request.use((req) => {
  req.headers['Accept-Language'] = useAppStore().settings.locale
  return req
})

client.interceptors.response.use(
  (res) => res.data,
  (err) => {
    if (!err.response) {
      throw err
    }

    setTimeout(() => {
      // 이미 컴포넌트에서 에러 토스트를 호출중이거나, 에러 토스트를 띄우지 않아야 하는 경우.
      // 따라서 개별 컴포넌트에서 의도적으로 에러 토스트를 띄우지 않는 경우, 이 코드 하단의 toast.error에서 기본적으로 에러메시지를 띄운다.
      if (useAppStore().toast || useAppStore().shouldSkipErrorToast) {
        useAppStore().setShouldSkipErrorToast(false)
        return
      }

      if (err.response.data.code === '1000') {
        helpers.toast.error('AUTH_EXPIRED')

        // userStore를 미리 생성하려 하면 순환 참조가 발생하여 런타임 에러가 발생함
        useAppStore().setCanSkipLeaveModal(true)
        useUserStore().signOut()
        return
      }

      if (err.response.data.code === '1001') {
        helpers.toast.error('ACCESS_DENIED')
        router.push('/')
        return
      }

      helpers.toast.error(err.response.data?.extra?.guide || ERROR_MESSAGE.apiError)
    })
    throw new CustomError(err.response)
  },
)

export const clientNoAuth = axios.create()

export const setRequestHeader = (header: { token: string }) => {
  if (header.token) {
    client.defaults.headers.Authorization = `Bearer ${header.token}`
  }
}

export const clearRequestHeader = () => {
  delete client.defaults.headers.Authorization
}

export default client
